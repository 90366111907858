<template>
  <div>
    <div class="container py-5 text-center">
      <div class="mx-auto"
           style="max-width: 500px;">
      <h3>{{ $t('fill_form_with_data_of_whom_you_recommend_and_we_will_contact_you') }}</h3>
      <GiftFormBlock :standalone="true"
                     :userId="userId"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import GiftFormBlock from "@/components/parts/marketing/GiftFormBlock.vue"
import { useRoute } from "vue-router"

const route = useRoute()

const userId = route.query?.uid || null
</script>

<style scoped lang="scss">
h3 {
  font-size: 18px;
}
</style>
