<template>
  <section id="pay_methods" class="pay_methods">
    <DataTable :options="options"
               module="superadmin"/>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/billing_pay_method"

export default {
  name: "SuperAdminPayMethods",
  components: {
    DataTable
  },
  head() {
    return {
      title: this.$t('pay_methods'),
    }
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
