<template>
  <div>
<!--    <SelectGroup :options="plan_visits"-->
<!--                 variant="white"-->
<!--                 v-if="dictionariesLoaded && rights.includes('planning')"-->
<!--                 :label="$t('treatment_plan')"-->
<!--                 :translate="false"-->
<!--                 :showlabel="true"-->
<!--                 :readonly="!canBeEdited"-->
<!--                 v-model="visit.visit_id"/>-->
    <SelectGroup :options="plan_treatments"
                 variant="white"
                 v-if="dictionariesLoaded && rights.includes('planning')"
                 :label="$t('treatment_plan')"
                 :translate="false"
                 :showlabel="true"
                 :readonly="!canBeEdited"
                 v-model="visit.visit_id"/>
    <SelectGroup :options="assistants"
                 v-if="assistantsLoaded"
                 title="full_name"
                 variant="white"
                 :showErrors="true"
                 :readonly="assistantEditDisabled"
                 validationId="assistant_id"
                 :label="$t('assistant')"
                 :showlabel="true"
                 v-model="visit.assistant_id"/>
    <div class="row" v-if="1 == 2">
      <div class="col-12">
        <label class="form-label">{{ $t('insurance_polis') }}</label>
      </div>
      <div class="col-9">
        <SelectGroup :options="polisesOptions"
                     v-if="polisesLoaded"
                     title="title"
                     variant="white"
                     :showErrors="true"
                     :readonly="!polisCanBeEdited"
                     validationId="insurance_polis_id"
                     :label="$t('insurance_polis')"
                     :showlabel="false"
                     v-model="visit.insurance_polis_id"/>
      </div>
      <div class="col-3">
        <a @click="addPolis"
           class="btn btn-themed btn-themed-outline btn-themed-squared w-100 add-polis-btn">
          <PlusSvg/>
        </a>
      </div>
    </div>
    <ServiceMultiSelect :label="$t('planned_services')"
                        :dblclick="true"
                        :readonly="!canBeEdited"
                        v-model="visit.planned_services"/>
    <InputGroup v-if="rights.includes('amo_id')"
                v-model="visit.amoId"
                :label="$t('amo_deal_id')"/>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import SelectGroup from "@/components/form/SelectGroup"
import { EloquentService, PatientService } from "@/services/api.service"
import { mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup.vue"
import PlusSvg from '@/assets/svg-vue/patients/plus_tag.svg'

export default {
  name: "VisitModalAdditionallyPart",
  components: {
    InputGroup,
    ServiceMultiSelect,
    SelectGroup,
    PlusSvg
  },
  props: {
    visit: {},
    counter: {}
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      dataTableCounter: state => state.datatable.dataTableCounter
    }),
    patient_id() {
      return this.counter ? this.counter : (this.visit.client_id ?? null)
    },
    canBeEdited() {
      return this.visit && this.visit.is_paid !== '1'
    },
    assistantEditDisabled() {
      return !this.visit || (this.visit.price > 0 && (this.visit.debt == 0 || (this.visit.payments && this.visit.payments.length)))
    },
    polisCanBeEdited() {
      if(!this.canBeEdited) return false
      return !(this.visit?.actual_services || []).length
    },
    polisesOptions() {
      return this.polises.filter(polis => !this.visit?.insurance_company_id || Number(polis.insurance_company_id) === Number(this.visit.insurance_company_id))
    },
    visitInsuranceCompanyId() {
      return this.visit?.insurance_company_id
    }
  },
  data() {
    return {
      plan_visits: [],
      plan_treatments: [],
      assistants: [],
      polises: [],
      dictionariesLoaded: false,
      assistantsLoaded: false,
      polisesLoaded: false
    }
  },
  methods: {
    loadDictionaries() {
      this.dictionariesLoaded = false
      this.polisesLoaded = false
      if(!this.patient_id) return
      PatientService.getVisitDictionaries(this.patient_id, this.visit ? this.visit.visit_id : null).then(res => {
        this.plan_visits = res.data.plan_visits
        this.plan_treatments = res.data.plan_treatments
        this.$store.commit('setPolises', res.data.polises)
        this.polises = res.data.polises
        this.dictionariesLoaded = true
        this.polisesLoaded = true
      })
    },
    loadAssistants() {
      EloquentService.dataTable('assistant', 1, { pagination: 999 }).then(res => {
        this.assistants = res.data.result.data
        this.assistantsLoaded = true
      })
    },
    addPolis() {
      this.$store.commit('setViewModel', 'insurance_polis')
      this.$store.commit('setModule', 'insurance')
      this.$store.commit('setSelectedModel', {
        patient: this.visit?.patient ?? null,
        patient_id: this.patient_id ?? null
      })
      this.$store.commit('setShowModal', true)
    }
  },
  mounted() {
    this.loadAssistants()
  },
  watch: {
    patient_id() {
      this.loadDictionaries()
    },
    counter() {
      this.loadDictionaries()
    },
    dataTableCounter() {
      this.loadDictionaries()
    },
    visitInsuranceCompanyId() {
      this.visit.insurance_polis_id = null
    }
  }
}
</script>

<style scoped lang="scss">
.add-polis-btn {
  height: 44px;
}
</style>
