<template>
  <div v-if="kkmEnabled" class="card rounded bg-gray px-4 py-3 border-0">
    <div class="">KKM:</div>
    <SelectGroup v-model="object.custom_data_kkm_fiscal"
                 :label="$t('fiscal_device')"
                 :showlabel="true"
                 :options="fiscalDevices"
                 variant="white"/>
    <SelectGroup v-model="object.custom_data_kkm_acquiring"
                 :label="$t('acquiring_device')"
                 :showlabel="true"
                 :options="acquiringDevices"
                 variant="white"/>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { KKMService } from "@/services/kkm.service"
import SelectGroup from "@/components/form/SelectGroup.vue"

const fiscalDevices = ref([])
const acquiringDevices = ref([])
const store = useStore()
const kkmEnabled = computed(() => store.getters.kkmEnabled)

onMounted(async () => {
  if(kkmEnabled.value) {
    const devices = (await KKMService.getDevices()).data?.ListUnit || []
    fiscalDevices.value = devices.filter(device => device.TypeDevice === 'Фискальный регистратор').map(device => ({ id: device.NumDevice, title: device.NameDevice }))
    acquiringDevices.value = devices.filter(device => device.TypeDevice === 'Эквайринговый терминал').map(device => ({ id: device.NumDevice, title: device.NameDevice }))
  }
})

defineProps({
  object: {
    type: Object,
  }
})
</script>
