export default {
    account_manager: "Аккаунт менеджер",
    administrator: "Адміністратор",
    administrators: "Адміністратори",
    are_you_sure_you_want_to_delete_field: "Ви впевнені, що хочете видалити напрямок",
    assistants: "Асистенти",
    block: "Заблокувати",
    by_days: "По днях",
    by_days_of_week: "По днях тижня",
    by_even_days: "По парних днях",
    by_odd_days: "По непарних днях",
    chair: "Крісло",
    chairs: "Крісла",
    config: "Конфігурація",
    confirm_password: "Повторити пароль",
    curator: "Куратор",
    curators: "Куратори",
    doctor_position: "Посада лікаря",
    doctor_specialty: "Спеціальність лікаря",
    field: "Напрямок",
    fields: "Напрямки",
    functionality: "Функціонал",
    login_and_password: "Логін і пароль",
    manual_pay_system: "Ручна платіжна система",
    not_worked: "Не відпрацьовано",
    partner: "Партнер",
    password_does_not_match_confirmation: "Пароль не співпадає з підтвердженням",
    percent: "Відсоток",
    period_from: "Період з",
    period_to: "Період до",
    phone2: "Телефон 2",
    profession: "Спеціальність",
    rate: "Ставка",
    rate_per_day: "Ставка на день",
    rate_per_hour: "Ставка за годину",
    rate_per_month: "Ставка на місяць",
    roles: "Ролі",
    schedule_conflicts_found: "Знайдено конфліктів розкладу з поточними записами",
    staff: "Персонал",
    technics: "Техніки",
    weekend: "Вихідний",
    without_warehouse_form_note: "Без прив'язки до складу, матеріали будуть зписуватися з основного складу або зі складу, прив'язаного до кресла",
    work_day: "Робочий день",
    work_days: "Робочі дні",
    work_schedule: "Графік роботи",
    work_time: "Робочий час",
    worked: "Відпрацьовано",
    user_data: "Дані користувача",
    user_will_assigned_rights_selected_for_that_role: "Користувачу будуть призначені права, вибрані для цієї ролі",
}
