<template>
  <div v-if="loaded">
    <TariffPaymentStatus class="mb-3 mt-3 font-weight-bold"
                         warning_class="text"
                         :isFree="clinic_tariff.isFree"
                         :paid_till="clinic_tariff.paid_till"/>
    <b-row>
      <b-col lg="5" md="5" cols="12">
        <div>
          <SelectGroup v-model="clinic_tariff.tariff_id"
                       :label="$t('tariff')"
                       :showlabel="true"
                       :readonly="isManager"
                       :translate="false"
                       :options="tariffs"
                       variant="white"
                       :showErrors="true"/>
          <ServiceMultiSelect :label="$t('paid_functionality')"
                              :dblclick="false"
                              model="doctor"
                              :readonly="isManager"
                              title="title"
                              :key="`paid_f_${counter}`"
                              :dbsearch="false"
                              :prepopulatedOptions="possiblePaid"
                              v-model="clinic_tariff.paid_functionality"/>
          <InputGroup :label="$t('additional_space') + ' (по 100Мб)'"
                      type="number"
                      v-model="clinic_tariff.additional_space"
                      :showErrors="true"
                      :readonly="isManager"
                      validationId="additional_space"/>
          <InputGroup :label="$t('additional_doctors')"
                      type="number"
                      v-model="clinic_tariff.additional_doctors"
                      :showErrors="true"
                      :readonly="isManager"
                      validationId="additional_doctors"/>
          <DateGroup :label="$t('free_day')"
                     v-if="!clinic_tariff.isFree && !isSuperManager"
                     :readonly="isManager || isSuperManager"
                     v-model="clinic_tariff.free_day"/>
          <DateGroup :label="$t('paid_till')"
                     v-if="!clinic_tariff.isFree && !isSuperManager"
                     :readonly="isManager || isSuperManager"
                     v-model="clinic_tariff.paid_till"/>
          <CheckboxGroup label="Возможность бесплатно разблокировать на 3 дня при просрочке оплаты"
                         v-if="!isManager"
                         fid="can_be_unblocked"
                         v-model="clinic_tariff.can_use_unblock"/>
          <CheckboxGroup label="Автопродление JetAPI"
                         v-if="!isManager && jetAPIIncluded"
                         fid="jetapi_autoprolong"
                         v-model="clinic_tariff.jetapi_autoprolong"/>
          <div class="mt-4 pb-4" v-if="!isManager">
            <button class="btn-themed btn-themed-squared" @click="save">
              {{ $t('save') }}
            </button>
            <button v-if="clinic_tariff.tariff_id && totals['usd'] > 0"
                    class="ml-3 btn-themed btn-themed-squared btn-themed-outline"
                    @click="acceptPayment">
              {{ $t('payment') }}
            </button>
            <button v-if="clinic_tariff.paid_till"
                    @click="nullifyPayment"
                    class="btn-themed btn-themed-squared btn-outline-danger ml-3">
              Сделать неоплаченным
            </button>
          </div>
        </div>
      </b-col>
      <b-col lg="7" md="7" cols="12">
        <div class="pl-md-4 mt-md-4 mt-5" style="max-width: 600px;">
          <table class="table-blue" style="table-layout: auto">
            <thead>
            <tr>
              <th>Следующий платеж</th>
              <th v-for="c in currencies" class="text-uppercase" :key="`cur-th-${c}`">{{ c }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(price, p) in priceParts" :key="`pp-${p}`">
              <td>{{ $t(p) }}</td>
              <td v-for="c in currencies"
                  :class="{ currencyActive: c === billingCurrency }"
                  :key="`cur-td-${p}-${c}`">
                {{ price[c] ? formatNumber(price[c], true) : '-' }}
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr class="font-weight-bold">
              <td>{{ $t('total') }}</td>
              <td v-for="c in currencies"
                  :class="{ currencyActive: c === billingCurrency }"
                  :key="`cur-td-tot-${c}`">
                {{ totals[c] ? formatNumber(totals[c], true) : '-' }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('manual') }}</td>
              <td v-for="c in currencies"
                  :class="{ currencyActive: c === billingCurrency }"
                  :key="`cur-td-manual-${c}`">
                <input v-model="clinic_tariff.custom_payment[c]"
                       class="custom-pay-input"
                       type="number"
                       :disabled="isManager"/>
              </td>
            </tr>
            </tfoot>
          </table>
          <div class="mt-3 mb-1">* Валюта оплат: <b>{{ billingCurrency }}</b></div>
          <AutosizeTextArea
              class="mt-4"
              :readonly="isManager"
              :showlabel="true"
              :label="$t('comment')"
              v-model="clinic_tariff.comment"
              :dictation="false"
              :minH="46"
          />
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import { SuperadminService} from "@/services/api.service"
import {mapState} from "vuex"
import SelectGroup from "@/components/form/SelectGroup"
import InputGroup from "@/components/form/InputGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { clinicTariffAdapter } from "@/adapters/superadmin/clinicTariff"
import {currencyForJson} from "@/dictionaries/dictionary"
import DateGroup from "@/components/form/DateGroup"
import TariffPaymentStatus from "@/components/parts/superadmin/TariffPaymentStatus"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import { formatNumber } from "@/extensions/filters/filters"

export default {
  name: "SuperAdminClinicTariffs",
  components: { CheckboxGroup, TariffPaymentStatus, DateGroup, ServiceMultiSelect, InputGroup, SelectGroup, AutosizeTextArea},
  head() {
    return {
      title: this.$t('tariff'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      dtcounter: state => state.datatable.dataTableCounter,
    }),
    selectedTariffId() {
      return this.clinic_tariff ? this.clinic_tariff.tariff_id : null
    },
    selectedTariff() {
      if(!this.selectedTariffId) return null
      return this.tariffs.find(x => x.id == this.selectedTariffId)
    },
    additionalSpace() {
      return this.clinic_tariff ? this.clinic_tariff.additional_space : 0
    },
    additionalDoctors() {
      return this.clinic_tariff ? this.clinic_tariff.additional_doctors : 0
    },
    paidFunctionality() {
      return this.clinic_tariff && this.clinic_tariff.paid_functionality ? this.clinic_tariff.paid_functionality : []
    },
    role() {
      return this.$store.state.auth.user.role
    },
    isManager() {
      return this.role === 'manager' || this.role === 'tech_specialist' || this.role === 'account_manager'
    },
    isSuperManager() {
      return this.role === 'supermanager'
    },
    jetAPIIncluded() {
      return !!this.paidFunctionality.find(x => x.id === 26)
    },
    totals() {
      let arr = {}
      let fields = ['advance_payment', 'tariff', 'paid_functionality', 'additional_space', 'additional_doctors']
      fields.forEach(field => {
        let prices = this.copyObject(this.priceParts[field])
        for (let p in prices) {
          if ({}.hasOwnProperty.call(prices, p)) {
            arr[p] = (arr[p] ?? 0) + parseFloat(prices[p])
          }
        }
      })
      return arr
    },
  },
  data() {
    return {
      counter: 1,
      loaded: false,
      tariffs: [],
      possiblePaid: [],
      clinic_tariff: null,
      billingCurrency: null,
      currencies: currencyForJson,
      priceParts: {
        advance_payment: {},
        tariff: {},
        paid_functionality: {},
        additional_space: {},
        additional_doctors: {}
      }
    }
  },
  methods: {
    formatNumber,
    async save() {
      await SuperadminService.saveTariffsForClinic(this.clinic.id, clinicTariffAdapter(this.clinic_tariff))
      this.$noty.info(this.$t('success_message'))
      await this.load()
    },
    acceptPayment() {
      this.$store.commit('setViewModel', 'billing_payment')
      this.$store.commit('setModule', 'superadmin')
      this.$store.commit('setSelectedModel', {
        clinic_id: {
          code: this.clinic.id,
          name: `[${this.clinic.id}] ${this.clinic.name}`
        }
      })
      this.$store.commit('setShowModal', true)
    },
    async load() {
      const d = (await SuperadminService.tariffsForClinic(this.clinic.id)).data
      this.billingCurrency = d.billingCurrency
      this.tariffs = d.tariffs
      this.clinic_tariff = d.clinic_tariff
      if(!this.clinic_tariff.custom_payment) {
        this.clinic_tariff.custom_payment = this.currencies.reduce((acc,curr)=> (acc[curr] = null, acc),{})
      }
      await this.$nextTick()
      this.loaded = true
    },
    async nullifyPayment() {
      if(confirm('Тариф станет неоплаченым, вы уверены?')) {
        await SuperadminService.nullifyPayment(this.clinic.id)
        await this.load()
      }
    }
  },
  async mounted() {
    await this.load()
  },
  watch: {
    selectedTariffId(val, oldval) {
      if(val) {
        if(oldval) {
          this.clinic_tariff.paid_functionality = []
        }
        let tariff = this.selectedTariff
        this.possiblePaid = tariff.paid_functions
        this.counter++
      }
    },
    selectedTariff(val) {
      this.priceParts.tariff = val.monthly_payment
      this.priceParts.advance_payment = this.clinic_tariff && this.clinic_tariff.advance_paid ? {} : val.advance_payment
    },
    additionalSpace(val) {
      if(!this.selectedTariff) { this.priceParts.additional_space = {}; return }
      let prices = this.copyObject(this.selectedTariff.price_per_additional_space)
      for (let p in prices) {
        if ({}.hasOwnProperty.call(prices, p)) prices[p] = prices[p] * val
      }
      this.priceParts.additional_space = prices
    },
    additionalDoctors(val) {
      if(!this.selectedTariff) { this.priceParts.additional_doctors = {}; return }
      let prices = this.copyObject(this.selectedTariff.price_per_additional_doctor)
      for (let p in prices) {
        if ({}.hasOwnProperty.call(prices, p)) prices[p] = prices[p] * val
      }
      this.priceParts.additional_doctors = prices
    },
    paidFunctionality(val) {
      if(!val) { this.priceParts.paid_functionality = {}; return }
      let prices = {}
      val.forEach(item => {
        let item_prices = JSON.parse(item.pivot.additional_payment)
        for (let p in item_prices) {
          if ({}.hasOwnProperty.call(item_prices, p)) {
            prices[p] = (prices[p] ?? 0) + parseInt(item_prices[p])
          }
        }
      })
      this.priceParts.paid_functionality = prices
    },
    async dtcounter() {
      this.loaded = false
      await this.load()
    }
  }
}
</script>

<style scoped lang="scss">
.custom-pay-input {
  max-width: 80px;
  border: 1px solid $gray-icon;
  border-radius: 4px;
  outline: none;
  padding: 3px 10px;
}
.currencyActive {
  background: #f2f6fa;
}
</style>
