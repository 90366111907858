export default {
    primaryKey: "id",
    model: 'billing_additional_service',
    modaltitle: 'service',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    rights: {
        add: "superadmin_payment_delete",
        edit: "superadmin_payment_delete",
        delete: "superadmin_payment_delete",
    },
    showTotalRecords: true,
    deletemessage: 'confirm_deletion',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'price',
            title: 'price',
            table: true,
            sortable: true,
            format_number: true,
            filter: true,
            filter_number: true,
            form: {
                type: "text",
                note: 'в тенге',
                required: true
            }
        },
    ]
}
