<template>
  <b-modal id="payment-return-modal"
           :title="$t('return')"
           size="sm"
           centered
           @show="onShow"
           @hide="emit('hide')">
    <InputGroup type="number"
                :label="$t('sum')"
                :required="true"
                v-model="amount"/>
    <SelectGroup :options="payMethods"
                 variant="white"
                 :label="$t('payment_form')"
                 :null-option="false"
                 title="translatedTitle"
                 :translate="false"
                 :showlabel="true"
                 v-model="payMethod"/>
    <div v-if="rights.includes('cashdesk') && payMethod != 7"
         class="d-inline-block mr-3">
      <CheckboxGroup :label="$t('fiscal_cheque')"
                     :fid="`ppo-checkbox`"
                     v-model="fiscal"/>
    </div>
    <ValidationError validationId="paid"/>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="ml-auto cancel d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>
        <button :disabled="loading || !amount"
                @click="submit"
                class="ml-3 btn-themed align-middle d-inline-block btn-themed-squared">
          {{ $t('send') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"
import { computed, getCurrentInstance, onBeforeMount, ref } from "vue"
import { EloquentService, PatientService } from "@/services/api.service"
import { KKMService } from "@/services/kkm.service"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import { useStore } from "vuex"
import SelectGroup from "@/components/form/SelectGroup.vue"

const store = useStore()
const emit = defineEmits(['hide'])
const amount = ref(0)
const payMethod = ref(null)
const fiscal = ref(false)
const loading = ref(false)

let i18n = null
let noty = null

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  noty = getCurrentInstance()?.appContext?.app?.noty
})

const props = defineProps({
  payment: Object,
})

const rights = computed(() => store.state.auth.rights)
const payMethods = computed(() => {
  const methods = store.state.auth.pay_methods
  return methods.filter(item => item.id !== 7 && item.id !== 100) // 7 is without payment
})
const clinic = computed(() => store.state.auth.clinic)
const branch = computed(() => store.state.auth.branch)
const ppoSettings = computed(() => clinic.value.ppo_settings ? JSON.parse(clinic.value.ppo_settings) : {})

const submit = async () => {
  loading.value = true
  try {
    const res = await PatientService.refundPay(props.payment.id, {
      amount: amount.value,
      forma: payMethod.value,
      fiscal: fiscal.value
    })
    if(fiscal.value && props.payment.fiscal_vendor === 'kkm') {
      await kkmPrintCheque(res.data)
    }
    emit('hide')
    noty.info(i18n.t('success_message'))
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}

const onShow = () => {
  // payMethod.value = props.payment.family_account ? 100 : props.payment.forma
  payMethod.value = props.payment.family_account ? 1 : props.payment.forma
  fiscal.value = !!props.payment.fiscal_vendor
}

const kkmPrintCheque = async (payment) => {
  if((ppoSettings.value[`kkm_acquiring_methods${branch.value}`] || []).includes(Number(payMethod.value))) {
    const universalId = props.payment?.meta_data?.kkmCardPaymentId || null
    // if(!universalId) {
    //   alert('Не известен банковский идентификатор транзакции')
    //   return
    // }
    if(universalId) {
      const payMethodDetails = (await EloquentService.show('pay_method', payMethod.value))?.data?.custom_data || null
      await KKMService.returnPaymentByPaymentCard(-Number(payment.paid), payMethodDetails?.kkm_acquiring || 0, universalId)
    }
  }
  try {
    const res = await KKMService.printCheque({
      totalPrice: -Number(payment.paid),
      totalPaid: -Number(payment.paid),
      payId: -payment.id,
      amount: -Number(payment.paid),
      payMethod: payment.forma,
      services: [],
      numDevice: 0,
      transactionType: 'refund'
    })
    if(res?.error) {
      noty.error(i18n.t('error_printing_cheque') + ': ' + res.error)
    }
    if(res?.data?.CheckNumber) {
      await PatientService.setUUIDForPayment(payment.id, { uuid: res.data.CheckNumber })
    }
  } catch (e) {
    console.log(e)
  }
}
</script>
