export default {
    "1_month_ago": "1 oydan kam",
    "3_months_ago": "3 oydan kam",
    "6_months_ago": "6 oydan kam",
    "12_months_ago": "12 oydan kam",
    "above_1_month": "1 oydan ko‘p",
    "above_2_months": "2 oydan ko‘p",
    "above_3_months": "3 oydan ko‘p",
    "above_6_months": "6 oydan ko‘p",
    "above_1_year": "1 yildan ko‘p",
    add_patients: "Bemorlarni qo‘shish",
    add_standard: "Standart qo‘shish",
    after_service: "Xizmatdan so‘ng",
    amount_of_patients: "Bemorlar soni",
    are_you_sure_you_want_to_delete_mailing: "Haqiqatan ham tarqatma xatlarni o‘chirmoqchimisiz?",
    birth_day: "Tug'ilgan kun",
    birth_month: "Tug'ilgan oy",
    cannot_be_delivered: "Yetkazib berish mumkin emas",
    channel: "Kanal",
    channels: "Kanallar",
    complex_status_changed: "Davolash rejasidan kompleksning holatini o'zgartirish",
    create_mailing: "Tarqatma xatlar yaratish",
    do_not_send_at_night_time: "Tun vaqtlarida yuborilmaydi (22:00 - 07:00)",
    does_not_contain: "Tarkibi yo'q",
    duplicate: "Nusxalash",
    given_time: "Berilgan vaqt",
    has_future_visit: "Kelgusi sanalar uchun tashrif mavjud",
    has_tags: "Belgiga ega",
    has_treatment_plan_with_status: "Holatga qarab davolanish rejasi mavjud",
    has_visit_with_status: "Mavqe bilan tashrifga ega",
    immediately_after_creating: "Yaratilgandan so‘ng darhol",
    launch_mailing: "Tarqatma xatlarni ishga tushirish",
    mailing: "Tarqatma xat",
    mailing_date: "Tarqatma xat sanasi",
    mailing_report: "Tarqatma xatlar bo'yicha hisobot",
    mailings: "Tarqatma xatlar",
    marketing: "Marketing",
    marketing_mailing: "Tarqatma xat",
    no_push_token: "Token mavjud emas",
    not_enough_balance: "Hisobda mablag‘ yetarli emas",
    of_days: "kunlar",
    of_hours: "soatlar",
    passed_since_last_visit: "Oxirgi tashrifdan beri o‘tdi",
    plan_status_changed: "Davolash rejasi holati o'zgardi",
    push_notification: "Push-bildirishnoma",
    push_notifications: "Push-bildirishnomalar",
    ready: "Tayyor",
    retry: "Yana bir marta urinib ko'rish",
    save_filter: "Filtrni saqlash",
    send_again: "Qayta yuborish",
    send_date: "Yuborish sanasi",
    send_info: "Yuborish to‘g‘risida ma’lumot",
    sent_slash_error: "Yuborildi / Xato",
    since_last_visit: "Oxirgi tashrifdan keyin",
    sms_message: "SМS-xabar",
    stop_mailing: "Xat tarqatishni to‘xtatish",
    tags_for_marketing_mailing: "Ma’lumotni avtomatik ravishda chiqarib olish uchun teglar:",
    time: "Vaqt",
    tokens: "Tokenlar",
    too_many_attempts: "Yuborish urinishlari juda koʻp",
    total_in_group: "Guruhda jami",
    treatment_plan_created: "Yangi davolash rejasi tuzildi",
    visit_time_changed: "Tashrif vaqti qayta rejalashtirilgan",
    users_selected: "Tanlangan foydalanuvchilar",
    wrong_credentials: "Yuborish xizmatining noto‘g‘ri avtorizatsiya qilingan ma’lumotlari",
    wrong_phone_number: "Noto‘g‘ri telefon raqami",

    january: "Yanvar",
    february: "Fevral",
    march: "Mart",
    april: "Aprel",
    may: "May",
    june: "Iyun",
    july: "Iyul",
    august: "Avgust",
    september: "Sentyabr",
    october: "Oktyabr",
    november: "Noyabr",
    december: "Dekabr",
}
