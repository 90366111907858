export const billingPaymentAdapter =
    ({
       clinic_id,
       sum,
       currency,
       pay_system_id,
       original,
       months,
       comment,
       confirmed,
       discount_percent,
       include_payment_for_core_tariff,
       paid_functionality,
       additional_services,
       additional_doctors,
       manager_id,
       account_manager_id,
       partner_id
     }) => ({
      clinic_id: clinic_id ? clinic_id.code : null,
      sum: sum ?? null,
      currency: currency ? currency : null,
      months: months || 1,
      comment: comment || null,
      pay_system_id: pay_system_id || null,
      original: original || '',
      pay_system: 'manual',
      paid: 1,
      confirmed: confirmed ? 1 : 0,
      discount_percent: discount_percent || 0,
      include_payment_for_core_tariff: include_payment_for_core_tariff ? 1 : 0,
      paid_functionality: (paid_functionality || []).map((item) => Number(item)),
      additional_services: additionalServicesAdapter(additional_services),
      additional_doctors: additional_doctors || 0,
      manager_id,
      account_manager_id,
      partner_id
    })

const additionalServicesAdapter = (additional_services) => {
  const res = []
  const data = additional_services || []
  data.forEach(item => {
    for(let i = 1; i <= item.amount; i++) {
      res.push({
        billing_service_id: item.id,
        price: item.price
      })
    }
  })
  return res
}
