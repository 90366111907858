export default {
    account_manager: "Hesab meneceri",
    administrator: "Administrator",
    administrators: "Administratorlar",
    are_you_sure_you_want_to_delete_field: "Göndərişi silmək istədiyinizə əminsiniz?",
    assistants: "Assistentlər",
    block: "Blokla",
    by_days: "Günlər üzrə",
    by_days_of_week: "Həftənin günləri üzrə",
    by_even_days: "Cüt günlər üzrə",
    by_odd_days: "Tək günlər üzrə",
    chair: "Kreslo",
    chairs: "Kreslolar",
    config: "Konfiqurasiya",
    confirm_password: "Parolu təkrar daxil edin",
    curator: "Kurator",
    curators: "Kuratorlar",
    doctor_position: "Həkimin vəzifəsi",
    doctor_specialty: "Həkimin ixtisası",
    field: "İstiqamət",
    fields: "İstiqamətlər",
    functionality: "Funksional",
    login_and_password: "Login və parol",
    manual_pay_system: "Əl ilə ödəmə sistemi",
    not_worked: "İşlənməyib",
    partner: "Tərəfdaş",
    password_does_not_match_confirmation: "Parol təsdiqlə uyğun gəlmir",
    percent: "Faiz",
    period_from: "Dövr başlayır",
    period_to: "Dövr bitir",
    phone2: "Əlavə telefon",
    profession: "İxtisas",
    rate: "Dərəcə",
    rate_per_day: "Günlük dərəcə",
    rate_per_hour: "Saatlıq dərəcə",
    rate_per_month: "Aylıq dərəcə",
    roles: "Rollar",
    schedule_conflicts_found: "Cari yazılarla cədvəl konfliktləri tapıldı",
    staff: "Personal",
    technics: "Texniklər",
    weekend: "İstirahət günü",
    without_warehouse_form_note: "Anbarla bağlı olmadan, materiallar əsas anbardan və ya kresloya bağlı anbardan silinəcək",
    work_day: "İş günü",
    work_days: "İş günləri",
    work_schedule: "İş qrafiki",
    work_time: "İş vaxtı",
    worked: "İşlənib",
    user_data: "İstifadəçi məlumatları",
    user_will_assigned_rights_selected_for_that_role: "İstifadəçiyə bu rol üçün seçilmiş hüquqlar təyin ediləcək",
}
