<template>
  <div class="gift-form h-100">
    <div class="row row-narrow mt-4">
      <div :class="{ 'col-md-4': !standalone, 'col-md-12': standalone }">
        <InputGroup v-model="name"
                    class="mb-0"
                    :show-errors="true"
                    validation-id="name"
                    :placeholder="$t('name')"/>
      </div>
      <div :class="{ 'col-md-4': !standalone, 'col-md-12': standalone }">
        <InputGroup v-model="phone"
                    class="mb-0"
                    :show-errors="true"
                    validation-id="phone"
                    :placeholder="$t('phone')"/>
      </div>
      <div :class="{ 'col-md-4': !standalone, 'col-md-12': standalone }">
        <button class="gift-btn btn btn-themed btn-themed-squared w-100"
                @click="submit">
          {{ $t('send') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import InputGroup from "@/components/form/InputGroup.vue"
import { MarketingService } from "@/services/api.service"
import { getCurrentInstance, onBeforeMount, ref } from "vue"
import { useStore } from "vuex"

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  standalone: {
    type: Boolean,
    default: false
  }
})

const store = useStore()

let i18n = null
let noty = null

const name = ref('')
const phone = ref('')

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  noty = getCurrentInstance()?.appContext?.app?.noty
})

const submit = async () => {
  store.commit('cleanValidationErrors')
  try {
    const res = await MarketingService.submitGiftApplication({
      name: name.value,
      phone: phone.value,
      uid: props.userId
    })
    if(res.data) {
      name.value = ''
      phone.value = ''
      noty.info(i18n.t('success_message'))
    }
  } catch (e) {
    console.log(e)
  }
}
</script>

<style scoped lang="scss">
.gift-btn {
  height: 46px;
}
</style>
