export default {
    primaryKey: 'id',
    model: 'record',
    order: 'start',
    sort: 'desc',
    actionscomponent: 'PatientDatatableRecordActions',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    trclass: [{ class: 'opacity-50', condition: 'isOtherBranch' }, { class: 'today', condition: 'isToday' }],
    showTotalRecords: true,
    actions: [
        // "add",
        // "delete"
    ],
    rights: {
        delete: "patients",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'start',
            title: 'visit',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            tdcomponent: "PatientsDatatableTDStart"
        },
        {
            id: 'record_type',
            title: 'type',
            table: true,
            sortable: true,
            format_translate: true,
        },
        {
            id: 'records_statuses_id',
            title: 'status',
            table: true,
            table_show: 'readableStatus',
            sortable: true,
            format_translate: true,
            tdcomponent: "PatientsDatatableTDRecordStatus"
        },
        {
            id: 'services',
            title: 'services',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDServices"
        },
        {
            id: 'diagnoses',
            title: 'diagnoses',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDDiagnoses"
        },
        {
            id: 'scans',
            title: 'scan',
            table: false,
            sortable: false,
            tdcomponent: "PatientsDatatableTDScan"
        },
        {
            id: 'teeth',
            title: 'teeth',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDTeeth"
        },
        {
            id: 'result_id',
            title: 'result',
            table: true,
            table_show: 'visit_result.translatedShortTitle',
            sortable: true,
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'branch_id',
            title: 'branch',
            table: true,
            table_show: 'branch.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title",
                },
                required: true
            }
        },
        {
            id: 'f043',
            title: 'f043',
            table: false,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: "PatientsDatatableTDF043"
        },
        {
            id: 'price',
            title: 'price',
            table: true,
            sortable: false,
            format_currency: true,
        },
        {
            id: 'paidSum',
            title: 'payment',
            table: true,
            sortable: false,
            format_currency: true,
            tdcomponent: "PatientsDatatableTDRecordPaid"
        },
    ]
}
