<template>
  <div class="">
    <InputGroup :show-errors="true"
                validation-id="title"
                v-model="object.title"
                :label="$t('title')"
                :required="true"/>
    <div class="radio-inline" v-if="!object.group_id">
      <RadioGroup v-model="object.task_type"
                  :label="$t('task_type')"
                  :showlabel="true"
                  :show-errors="true"
                  validation-id="task_type"
                  :options="taskTypes"/>
    </div>
    <b-row>
      <b-col lg="6">
        <DateTimeGroup :label="$t('start_period')"
                       v-model="object.start"/>
      </b-col>
      <b-col lg="6">
        <DateTimeGroup :label="$t('deadline')"
                       v-model="object.deadline"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <ServiceMultiSelect :label="$t('patient')"
                            :dblclick="false"
                            v-if="!object.group_id"
                            model="patient"
                            :multiple="false"
                            title="full_name"
                            :dbsearch="true"
                            v-model="object.patient"/>
      </b-col>
      <b-col md="6">
        <InputGroup :label="$t('phone')"
                    v-if="!object.group_id"
                    v-model="object.phone"/>
      </b-col>
    </b-row>
    <AutosizeTextArea v-model="object.description"
                      :show-errors="true"
                      :label="$t('description')"
                      validation-id="description"/>

    <ServiceMultiSelect :label="$t('responsible')"
                        :dblclick="false"
                        model="staff"
                        :multiple="true"
                        title="full_name"
                        :dbsearch="true"
                        v-model="object.users"/>
    <div class="radio-inline">
      <RadioGroup v-model="object.importance"
                  :label="$t('importance')"
                  :showlabel="true"
                  :show-errors="true"
                  validation-id="importance"
                  :options="taskImportances"/>
    </div>
    <div v-if="!object.group_id">
      <label class="btn-themed btn-themed-outline btn-themed-squared" for="files">
        <ClipSvg class="mr-2"/>{{ $t('attach_file') }}
      </label>
      <input type="file"
             accept=".xls, .jpg, .xlsx, .doc, .docx, .pdf, .txt, .jpeg, .png, .gif"
             class="d-none"
             id="files"
             @change="processFile($event)"/>
      <div v-for="(f, find) in files" :key="`file-${find}`" class="mb-1 pt-1">
        <a v-if="f.original" :href="f.fileUrl" target="_blank">
          {{ f.original }}
        </a>
        <span v-else>{{ f.name }}</span>
        <a class="ml-2 pointer" @click="deleteFile(find, f)">
          <CloseSvg class="svg-gray close-svg"/>
        </a>
      </div>
    </div>
    <GeneralCommentsBlock :object="object"
                          class="mt-4"
                          model="task_comment"
                          parent-column="task_id"/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import { taskImportances, taskTypes } from "@/dictionaries/dictionary"
import InputGroup from "@/components/form/InputGroup"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import RadioGroup from "@/components/form/RadioGroup"
import DateTimeGroup from "@/components/form/DateTimeGroup"
import ClipSvg from '@/assets/svg-vue/form/clip.svg'
import CloseSvg from '@/assets/svg-vue/general/close.svg'
import JwtService from "@/services/jwt.service"
import GeneralCommentsBlock from "@/components/parts/general/GeneralCommentsBlock"

export default {
  name: "CRMDatatableTaskForm",
  components: {
    GeneralCommentsBlock,
    RadioGroup,
    ServiceMultiSelect,
    InputGroup,
    AutosizeTextArea,
    DateTimeGroup,
    ClipSvg,
    CloseSvg
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      taskImportances: taskImportances,
      taskTypes: taskTypes,
      sizelimit: 20,
      files: [],
      filesToDelete: [],
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      model: state => state.datatable.viewModel,
      loading: state => state.dom.loading
    }),
    edit() {
      return !!this.object[this.options.primaryKey]
    },
    preUrl() {
      let link = JwtService.getPrintPrelink(this.clinic)
      return link+'/task/file/'
    },
    patient() {
      return this.object.patient
    }
  },
  methods: {
    processFile(event) {
      let file = event.target.files[0]
      if(!file) return
      if(file.size > (this.sizelimit * 1024 * 1024)) {
        this.$noty.error(this.$t('file_size_is_too_big'))
        return
      }
      this.files.push(file)
    },
    deleteFile(index, file) {
      this.files.splice(index, 1)
      if(file.id) {
        this.filesToDelete.push(file.id)
        this.$set(this.object, 'filesToDelete', this.filesToDelete)
      }
    },
  },
  mounted() {
    if(this.object.files) {
      this.files = this.object.files
    }
  },
  watch: {
    files: {
      handler(val) {
        this.$set(this.object, 'files', val)
      },
      deep: true
    },
    patient: {
      handler(val) {
        if(val) {
          this.object.phone = val.phone
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.close-svg {
  width: 12px;
  height: 12px;
  position: relative;
  top: -1px;
}
</style>
