<template>
  <div class="form-group">
    <TagsList type="notifications"
              class="mt-0">
      {{ $t('tags_for_notifications') }}
    </TagsList>
    <div class="mt-4">
      <label class="form-label">{{ $t('notification_channel') }}</label>
      <div>
        <CheckboxGroup v-for="n in selectModels.notification_channels"
                       class="d-inline-block mr-4"
                       :fid="`tag-${n.id}`"
                       :key="`ch-${n.id}`"
                       :checkboxvalue="n.id"
                       v-model="object.notification_channels"
                       :label="n.title === 'WebPush' ? $t('push_notifications') : n.title"/>
      </div>
    </div>

    <div v-if="['before_visit', 'after_visit', 'since_last_visit', 'after_service'].includes(object.notification_type)">
      <label class="form-label">{{ $t('send_time') }}</label>
      <b-row>
        <b-col lg="3" md="4" cols="6">
          <InputGroup class="mb-0"
                      type="number"
                      placeholder="0"
                      v-model="object.minutes"/>
        </b-col>
        <b-col lg="3" md="4" cols="6">
          <SelectGroup v-model="object.timeType"
                       class="mb-0"
                       :options="timeTypes"
                       variant="white"/>
        </b-col>
      </b-row>
      <div class="small text-muted">
        <div v-if="object.notification_type == 'before_visit'">{{ $t('time_before_visit') }}</div>
        <div v-if="object.notification_type == 'after_visit'">{{ $t('time_after_visit') }}</div>
      </div>
    </div>

    <div v-if="['visit_cancel', 'visit_created', 'visit_time_changed'].includes(object.notification_type)">
      <CheckboxGroup class="d-inline-block mt-3"
                     fid="send_only_during_day"
                     v-model="object.send_only_during_day"
                     :label="$t('do_not_send_at_night_time')"/>
    </div>

  </div>
</template>

<script>

import CheckboxGroup from "@/components/form/CheckboxGroup"
import InputGroup from "@/components/form/InputGroup"
import {timeTypes} from "@/dictionaries/dictionary"
import SelectGroup from "@/components/form/SelectGroup"
import TagsList from "@/components/parts/crm/TagsList.vue"

export default {
  name: "SettingsDatatableFormChannels",
  components: {
    TagsList,
    SelectGroup,
    InputGroup,
    CheckboxGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  data() {
    return {
      timeTypes: timeTypes,
    }
  },
  methods: {
    parseMinutes() {
      let timeType = 'minutes'
      if(this.object.minutes) {
        if(this.object.minutes % (24 * 60) === 0) {
          this.$set(this.object, 'minutes', this.object.minutes / (24 * 60))
          timeType = 'days'
        } else if(this.object.minutes % (60) === 0) {
          this.$set(this.object, 'minutes', this.object.minutes / (60))
          timeType = 'hours'
        }
      }
      this.$set(this.object, 'timeType', timeType)
    }
  },
  mounted() {
    this.parseMinutes()
    if(this.object.notification_channels) {
      this.object.notification_channels = this.object.notification_channels.map(({id}) => id)
    }
  },
}
</script>
