export default {
	fill_form_with_data_of_whom_you_recommend_and_we_will_contact_you: "Shaklni siz tavsiya qilgan shaxsning tafsilotlari bilan to'ldiring, biz siz bilan bog'lanamiz:",
	fill_form_yourself: "Shaklni o'zingiz to'ldiring!",
	how_referral_system_works: "Yo'naltirish tizimi qanday ishlaydi?",
	immediately_after_first_payment_of_your_referral: "Yo'naltiruvchingizning birinchi to'lovidan so'ng darhol",
	let_your_friend_scan_qr: "Do'stingizga QR kodini skanerlashiga ruxsat bering",
	let_your_friend_scan_qr_below: "Do'stingizga quyidagi QR kodni skanerlashiga ruxsat bering",
	or: "yoki",
	receive_payment_from_our_manager_on_your_card: "Menejerimizdan kartangizga to'lovni qabul qiling.",
	receive_reward_for_first_payment: "Yo'llanmaning birinchi to'lovi uchun <span class='text-primary'>{reward}</span> {currency} oling.",
	recommend_dentist_plus_to_your_friends: "Hamkorlik dasturi orqali do'stlaringizga Dentist + ni tavsiya eting va mukofotlarga ega bo'ling:",
	recommend_dentist_plus_to_your_friends_with_any_method: "Tish shifokori + dasturini o'zingiz tanlagan har qanday usuldan foydalanib do'stlaringizga tavsiya eting:",
	referral_system_for_users: 'Foydalanuvchilar uchun tavsiyalar tizimi',
	start_earning_right_now: 'Hoziroq pul ishlashni boshlang!'
}
