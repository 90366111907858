<template>
  <div class="form-group">
    <label class="form-label"
           :data-content="label"
           :class="{ 'd-block': labelline }">
      <span class="">{{ label }}</span>
      <span v-if="required"> *</span>
    </label>
    <div v-if="value && !fileremoved && !selected" class="position-relative d-inline-block">
      <div class="img-preview ml-2" v-if="filetype === 'image'">
        <expandable-image class="img-preview"
                          alt="image"
                          :src="value"/>
      </div>
      <div v-if="filetype === 'word'">
        <a class="text-primary ml-3 pointer"
           @click="linkToDoc">
          {{ value }}
        </a>
      </div>
      <a class="removeFile"
         @click.prevent="deleteFile"
         v-if="deletefile">
        <CloseSvg/>
      </a>
    </div>
    <div v-else>
      <div v-if="readonly">-</div>
      <label class="file-label d-md-flex d-block text-center align-items-center justify-content-center position-relative"
             :class="{ 'bg-light': draggedOver }"
             v-if="!readonly"
             @dragover.prevent="draggedOver = true"
             @dragleave.prevent="draggedOver = false"
             @drop.stop.prevent="dropFile"
             :for="fid">
        <!--        <span class="ml-2" v-if="formatnote">({{ $t(formatnote) }})</span>-->
        <!--                <span class="form-placeholder">{{ name }}</span>-->
        <!--        <span class="file-btn selected" v-if="selected">{{ $t('selected') }}</span>-->
        <template v-if="selected">
          <template v-if="filetype == 'image'" >
            <img v-for="(img, imgind) in filedata"
                 :key="`img-${imgind}`"
                 :src="img"
                 class="img-preview mx-1"/>
          </template>

          <div v-else>
            <div v-for="(name, nameind) in names" :key="`name-${nameind}`">
              {{ name }}
            </div>
          </div>
<!--          <span v-if="files.length > 1" class="ml-2">+ {{ files.length - 1 }}</span>-->
          <a class="removeFile" @click.prevent="deleteFile">
            <CloseSvg/>
          </a>
        </template>
        <template v-else>
          <div class="mr-md-4 mt-md-0 mt-3 d-md-inline-block d-block mb-2 mb-md-0">
            <ImgSvg class="file-upload-svg"/>
          </div>
          <div class="d-md-inline-block d-block">
            <div>{{ $t('drag_file_here_or_select') }}</div>
            <div class=" text-muted">{{ $t('requirements') }}: {{ requirements }}, {{ $t('less') }} {{ sizelimit }} Mb</div>
          </div>

        </template>
      </label>
      <input type="file"
             :accept="accept"
             class="d-none"
             :multiple="multiple"
             :id="fid"
             @change="processFile($event)"/>
    </div>
  </div>
</template>

<script>

import CloseSvg from "@/assets/svg-vue/general/close.svg"
import ImgSvg from "@/assets/svg-vue/form/file_upload.svg"
import JwtService from "@/services/jwt.service"
import {mapState} from "vuex"

export default {
  components: {
    CloseSvg,
    ImgSvg
  },
  data() {
    return {
      names: [this.label],
      selected: false,
      fileremoved: false,
      filedata: [],
      files: [],
      draggedOver: false
    }
  },
  props: {
    label: {},
    deletefile: {
      type: Boolean,
      default: false
    },
    labelline: {
      type: Boolean,
      default: false
    },
    formatnote: {},
    value: {},
    filetype: {
      type: String,
      required: true
    },
    sizelimit: { // in MB
      type: Number,
      default: 2
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fid: {},
    readonly: {},
    required: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
  },
  methods: {
    dropFile(event) {
      this.draggedOver = false
      this.processFile({
        target: {
          files: event.dataTransfer.files
        }
      })
    },
    processFile(event) {
      if(this.names.length > 0 && this.names[0] == this.label) this.names.shift()
      if(this.multiple) {
        let files = event.target.files
        let data = this.value || []
        files.forEach(file => {
          let f = this.handleFile(file)
          if(f) data.push(f)
        })
        this.$emit('input', data)
      }
      if(!this.multiple) {
        let file = event.target.files[0]
        if(!file) {
          return
        }
        let f = this.handleFile(file)
        if(f) this.$emit('input', f)
      }
      this.files = event.target.files
    },
    handleFile(file) {
      if(file.size > (this.sizelimit * 1024 * 1024)) {
        this.$noty.error(this.$t('file_size_is_too_big'))
        return
      }
      this.names.push(file.name)

      this.selected = true

      let _this = this

      if(this.filetype === 'image') {
        let reader = new FileReader()
        reader.onload = function(e) {
          _this.filedata.push(e.target.result)
        }
        reader.readAsDataURL(file)
      }

      return file
    },
    deleteFile() {
      this.fileremoved = true
      this.selected = false
      this.filedata = []
      this.names = []
      this.$emit('input', this.multiple ? [] : null)
      this.$emit('deleteFile')
    },
    linkToDoc() {
      let link = JwtService.getPrintPrelink(this.clinic, this.superadmin)
      link = link+'/template/document/'+this.$store.state.datatable.selectedModel.id
      if(this.superadmin) {
        link = link + `?superadmin_clinic_id=${this.clinic.id}`
      }
      window.open(link, '_blank')
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
    }),
    accept() {
      if(this.filetype === 'image') {
        return 'image/*'
      }
      if(this.filetype === 'document') {
        return 'application/pdf, .txt, .doc, .docx, .xls, .xlsx'
      }
      if(this.filetype === 'graph_document') {
        return 'image/*, application/pdf'
      }
      if(this.filetype === 'pdf') {
        return 'application/pdf'
      }
      if(this.filetype === 'word') {
        return '.docx'
      }
      if(this.filetype === 'excel') {
        return '.xls, .xlsx'
      }
      return '*/*'
    },
    requirements() {
      if(this.accept == 'image/*') {
        return 'jpeg, png, tiff, bmp'
      }
      if(this.filetype === 'document') {
        return 'pdf, txt, doc, docx, xls, xlsx'
      }
      if(this.filetype === 'graph_document') {
        return 'jpeg, png, tiff, bmp, pdf'
      }
      if(this.filetype === 'pdf') {
        return 'pdf'
      }
      if(this.filetype === 'word') {
        return 'docx'
      }
      return this.accept
    }
  }
}
</script>

<style scoped lang="scss">
.file-btn {
  &.selected {
    background: #000;
    color: #fff;
  }
}
.img-preview:not(.expanded) {
  max-width: 60px;
  img {
    max-width: 60px;
  }
}
.removeFile {
  position: absolute;
  top: -10px;
  right: -10px;
  box-shadow: 0px 0px 5px rgba(146, 169, 176, 0.3);
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 10px;
    height: 10px;
    fill: #000;
  }
}
.file-label {
  cursor: pointer;
  background: #FFFDFD;
  border-radius: 5px;
  border: 1px dashed $gray-icon;
  width: 100%;
  height: 80px;
  font-size: 12px;
  &:hover {
    background: #fff;
  }
}
.file-upload-svg {
  width: 52px;
  height: 52px;
}

@media screen and (max-width: 768px) {
  .file-label {
    height: 110px;
  }
  .file-upload-svg {
    width: 40px;
    height: 40px;
  }
}

</style>
