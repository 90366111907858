import { superUserRoles } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'super_user',
    order: 'id',
    sort: 'desc',
    showTotalRecords: true,
    // deletemessage: 'are_you_sure_you_want_to_delete_service',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    // rights: {
    //     edit: "clinics_update",
    //     delete: "clinics_delete",
    // },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'full_name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            // form: {
            //     type: "text",
            //     required: true,
            // },
            // tdcomponent: 'SuperAdminDatatableTDClinicTitle',
        },
        {
            id: 'role',
            title: 'role',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "select",
                select_type: "list",
                options: superUserRoles,
                hideNullOption: true,
                required: true,
            }
        },
        {
            id: 'fname',
            title: 'name',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'lname',
            title: 'surname',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'mname',
            title: 'middle_name',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'login',
            title: 'login',
            table: false,
            filter: false,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'password',
            title: 'password',
            table: false,
            filter: true,
            form: {
                type: "password"
            }
        },
        {
            id: 'bonus_on_first_payment',
            title: 'Бонус на первый платеж (%)',
            table: false,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
            form: {
                type: "number",
                required: true,
                classes: 'col-md-6',
                conditionFunc(store, object) {
                    return object.role === 'partner'
                }
            }
        },
        {
            id: 'bonus_on_first_3_payments',
            // title: 'Бонус на первые 3 платежа (%)',
            title: 'Бонус на оплаты клиента в теч. 3х месяцев (%)',
            table: false,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
            form: {
                type: "number",
                required: true,
                classes: 'col-md-12',
                conditionFunc(store, object) {
                    return object.role === 'manager'
                }
            }
        },
        {
            id: 'bonus_on_first_12_payments',
            title: 'Бонус на оплаты клиента в теч. 12-ти месяцев (%)',
            table: false,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
            form: {
                type: "number",
                required: true,
                classes: 'col-md-6',
                conditionFunc(store, object) {
                    return object.role === 'partner'
                }
            }
        },
        {
            id: 'bonus_on_all_payments',
            title: 'Бонус на все платежи (%)',
            table: false,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
            form: {
                type: "number",
                required: true,
                classes: 'col-md-12',
                conditionFunc(store, object) {
                    return object.role === 'account_manager'
                }
            }
        },
        {
            id: 'blocked',
            title: 'blocked',
            table: true,
            sortable: true,
            filter: true,
            filter_boolean: true,
            tdcomponent: 'SuperAdminDatatableTDUserBlocked',
            form: {
                type: "checkbox"
            }
        },
    ]

}
