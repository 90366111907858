export default {
    "1_month_ago": "менее 1 месяца",
    "3_months_ago": "менее 3 месяцев",
    "6_months_ago": "менее 6 месяцев",
    "12_months_ago": "менее 12 месяцев",
    "above_1_month": "более 1 месяца",
    "above_2_months": "более 2 месяцев",
    "above_3_months": "более 3 месяцев",
    "above_6_months": "более 6 месяцев",
    "above_1_year": "более 1 года",
    add_patients: "Добавить пациентов",
    add_standard: "Добавить стандартные",
    after_service: "После получения услуги",
    amount_of_patients: "Количество пациентов",
    are_you_sure_you_want_to_delete_mailing: "Вы действительно хотите удалить рассылку?",
    birth_day: "День рождения",
    birth_month: "Месяц рождения",
    cannot_be_delivered: "Не может быть доставлено",
    channel: "Канал",
    channels: "Каналы",
    complex_status_changed: "Смена статуса комплекса из плана лечения",
    create_mailing: "Создать рассылку",
    do_not_send_at_night_time: "Не отправлять в ночное время (22:00 - 07:00)",
    does_not_contain: "Не содержит",
    duplicate: "Копировать",
    given_time: "Заданное время",
    has_future_visit: "Есть визит на будущие даты",
    has_tags: "Имеет метки",
    has_treatment_plan_with_status: "Имеет план лечения со статусом",
    has_visit_with_status: "Имеет визит со статусом",
    immediately_after_creating: "Сразу после создания",
    launch_mailing: "Запустить рассылку",
    mailing: "Рассылка",
    mailing_date: "Дата рассылки",
    mailing_report: "Отчет по рассылкам",
    mailings: "Рассылки",
    marketing: "Маркетинг",
    marketing_mailing: "Рассылка",
    no_push_token: "Отсутствует токен",
    not_enough_balance: "Недостаточно средств на счету",
    of_days: "дней",
    of_hours: "часов",
    passed_since_last_visit: "Прошло с последнего визита",
    plan_status_changed: "Изменен статус плана лечения",
    push_notification: "Push-уведомление",
    push_notifications: "Push-уведомления",
    ready: "Готово",
    retry: "Попробовать еще раз",
    save_filter: "Сохранить фильтр",
    send_again: "Отправить повторно",
    send_date: "Дата отправки",
    send_info: "Информация об отправке",
    sent_slash_error: "Отправлено / Ошибка",
    since_last_visit: "После последнего визита",
    sms_message: "СМС-сообщение",
    stop_mailing: "Остановить рассылку",
    tags_for_marketing_mailing: "Тэги для автоматического вывода информации:",
    time: "Время",
    tokens: "Токены",
    too_many_attempts: "Слишком много попыток отправки",
    total_in_group: "Всего в группе",
    treatment_plan_created: "Создан новый план лечения",
    visit_time_changed: "Время визита перенесено",
    users_selected: "Выбрано пользователей",
    wrong_credentials: "Неверные авторизационные данные сервиса отправки",
    wrong_phone_number: "Неверный номер телефона",

    january: "Январь",
    february: "Февраль",
    march: "Март",
    april: "Апрель",
    may: "Май",
    june: "Июнь",
    july: "Июль",
    august: "Август",
    september: "Сентябрь",
    october: "Октябрь",
    november: "Ноябрь",
    december: "Декабрь",
}
