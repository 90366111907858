<template>
  <section id="billing_currency_rates"
           class="billing_currency_rates pt-4" style="max-width: 320px">
    <div v-for="rate in rates"
         :key="`rate-${rate.id}`">
      <div class="d-flex align-items-center mb-3">
        <div class="nowrap">1 {{ rate.currency_from }} =</div>
        <div class="px-4 flex-grow-1">
          <div>
            <InputGroup v-model="rate.rate"
                        class="mb-0"/>
          </div>
        </div>
        <div>
          {{ rate.currency_to }}
        </div>
      </div>
    </div>
    <div class="text-right mt-5">
      <button :disabled="loading"
              class="btn-themed btn-themed-squared w-100"
              @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </section>
</template>

<script setup>
import { EloquentService } from "@/services/api.service"
import {getCurrentInstance, onBeforeMount, onMounted, ref} from "vue"
import InputGroup from "@/components/form/InputGroup.vue"

const rates = ref([])
const loading = ref(false)

let i18n = null
let noty = null

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  noty = getCurrentInstance()?.appContext?.app?.noty
})

onMounted(() => {
  loadData()
})

const loadData = async () => {
  const data = await EloquentService.dataTable('billing_currency_rate', 1, {
    pagination: 1000,
  }, true, true)
  rates.value = data.data.result.data
}

const save = async () => {
  for(const rate of rates.value) {
    const formData = new FormData()
    formData.append('rate', rate.rate)
    await EloquentService.update('billing_currency_rate', rate.id, formData, true)
  }
  noty.info(i18n.t('success_message'))
}
</script>
