<template>
  <section id="payments"
           class="payments overflow-auto">
    <DataTable :options="options"
               :can-hide-rows="true"
               :wi-auto="true"
               module="superadmin"/>
    <TariffFunctionalityModal/>
    <PayStatusModal/>
  </section>
</template>

<script>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/billing_payment"
import TariffFunctionalityModal from "@/components/parts/superadmin/TariffFunctionalityModal"
import PayStatusModal from "@/components/parts/superadmin/PayStatusModal"

export default {
  name: "SuperAdminPayments",
  components: {
    PayStatusModal,
    TariffFunctionalityModal,
    DataTable
  },
  head() {
    return {
      title: this.$t('payments'),
    }
  },
  data() {
    return {
      options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
