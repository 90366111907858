import general from './en_general'
import header from './en_header'
import sidebar from './en_sidebar'
import calendar from './en_calendar'
import warehouses from './en_warehouses'
import patients from './en_patients'
import services from './en_services'
import staff from './en_staff'
import settings from './en_settings'
import accounting from "./en_accounting"
import reports from "./en_reports"
import templates from "./en_templates"
import crm from "./en_crm"
import egisz from "./en_egisz"
import marketing from "./en_marketing"
import insurance from "./en_insurance"
import planning from "./en_planning"
import cashdesk from "./en_cashdesk"
import tags from "./en_tags"
import referral from "./en_referral"
import nontranslated from "./en_nontranslated"

export default {
    ...general,
    ...header,
    ...sidebar,
    ...calendar,
    ...warehouses,
    ...patients,
    ...services,
    ...staff,
    ...accounting,
    ...reports,
    ...templates,
    ...cashdesk,
    ...planning,
    ...crm,
    ...egisz,
    ...marketing,
    ...insurance,
    ...settings,
    ...tags,
    ...referral,
    ...nontranslated,
}
