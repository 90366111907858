import { warehouseDocumentAdapter } from "@/adapters/warehouse/warehouseDocument"
import { warehouseOperationTypes, warehouseOperationTypesToAdd } from "@/dictionaries/dictionary"

export default {
    primaryKey: 'id',
    model: 'warehouseoperation',
    modaltitle: "documents",
    order: 'id',
    sort: 'desc',
    adapter: warehouseDocumentAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_document',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    actionscomponent: 'WarehouseDatatableActionsOperation',
    editCondition(object) {
        return object.status === 'draft' && object.operation_type != 'movement_to'
    },
    deleteCondition(object) {
        return object.status === 'draft' && object.operation_type != 'movement_to'
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                component: 'WarehouseDatatableFormTitle'
            },
            tdcomponent: 'WarehouseDatatableTDDocumentTitle',
        },
        {
            id: 'operation_type',
            title: 'type',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            filter_list_options: warehouseOperationTypes,
            form: {
                type: "select",
                select_type: "list",
                options: warehouseOperationTypesToAdd,
                disabled_on_edit: true,
                required: true
            }
        },
        {
            id: 'warehouse_id',
            title: 'warehouse',
            table: true,
            table_show: 'warehouse.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'warehouse_id',
            validation_id: 'related_warehouse_id',
            title: 'to_where',
            table: false,
            form: {
                component: 'WarehouseDatatableFormDestinationWarehouse',
            }
        },
        {
            id: 'forma',
            title: 'payment_type',
            table: false,
            form: {
                component: 'WarehouseDatatableFormPaymentType',
            }
        },
        {
            id: 'supplier_id',
            title: 'supplier',
            table: false,
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title",
                },
                component: 'WarehouseDatatableFormSupplier'
            }
        },
        {
            id: 'status',
            title: 'status',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                hidden: true,
                select_type: "list",
                options: {
                    "draft": { id: "draft", title: "draft" },
                    "finished": { id: "finished", title: "finished" }
                },
            }
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            filter: true,
            form: {
                type: "textarea"
            }
        }
    ]
}
