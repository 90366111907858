export default {
    add_call_task: "Add calling task",
    add_to_funnel: "Add to funnel",
    add_to_funnel_came_and_repeated: "Add to funnel 'Came and repeated'",
    all_days: "All days",
    amo_deal_id: "Amo deal ID",
    api_key: "API key",
    applications_and_appeals: "Applications and appeals",
    are_you_sure_you_want_to_delete_task: "Are you sure you want to delete task?",
    attach_file: "Attach file",
    binotel: "Binotel",
    binotel_integration: "Binotel integration",
    binotel_integration_instructions: "In order to integrate Dentist Plus with Binotel you need: <br>1. to create account in Binotel<br>2. write to Binotel techsupport asking them to set up the following webhook for <b>API CALL SETTINGS</b> and <b>API CALL COMPLETED</b>:<br><span class='text-primary'>{url}</span><br>3. in the field below, specify the users from the clinic staff who will use the binotel functionality:",
    binotel_key: "Binotel key",
    binotel_secret: "Binotel secret",
    binotel_internal: "Binotel internal number",
    button_title: "Button title",
    call_from_patient: "Call from patient",
    call_from_phone: "Call from phone",
    call_noun: "Call",
    callee: "Callee",
    cancel_sending: "Cancel sending",
    change_status: "Status of call",
    client_comment: "Client comment",
    copied: "Copied",
    copy_to_clipboard: "Copy to clipboard",
    create_application_on_call: "Create an application on call in application and appeals automatically",
    create_card_automatically: "Create card automatically",
    create_lead: "Create a lead",
    create_lead_on_incoming_messages: "Create a lead on incoming messages in whatsapp from unknown number",
    crm: "CRM",
    curator_assigned: "Curator assigned",
    deadline: "Deadline",
    deal: "Deal",
    do_not_create_duplicates: "Do not crete duplicates",
    do_not_create_duplicates_on_call: "Do not create duplicates for calls if there is already an active application until the status \"Recorded\"",
    do_not_show_anymore: "Do not show anymore",
    feedback: "Feedback",
    feedbacks: "Feedbacks",
    follow: "Follow",
    for_came_from_lead_funnel: "For those who came from leads funnel",
    for_came_on_first_visit: "For those who came on first visit",
    for_came_to_consultation: "For those who came to consultation",
    for_incoming: "For incoming",
    for_missed: "For missed",
    for_outcoming: "For outcoming",
    funnel_came_and_repeated: "Funnel: Came and repeated",
    generate_key: "Generate key",
    get_gift: "Get gift",
    has_overdue_tasks: "Has overdue tasks",
    has_tasks: "Has tasks",
    has_today_task: "Has today tasks",
    if_lead_has_no_responsible_then_assign_to_this_person_on_first_save: "If lead has no responsible person set, then assign to this person on first save",
    inprogress: "In progress",
    insert_script_one_time_instruction: "Put this script in <head> section",
    insert_widget_button_instruction: "Place this code where you want the button to appear",
    instruction: "Instruction",
    integration_key: "Integration key",
    internal_phone: "Internal phone",
    lead_channel: "Lead channel",
    leave_comment: "Leave a comment",
    manually_selected_user: "Manually selected user",
    mark_as_completed: "Mark as completed",
    mark_as_finished: "Mark as finished",
    mark_as_in_progress: "Mark as in progress",
    move_to_funnel_came_and_repeated: "Move to funnel 'Came and repeated'",
    my_tasks: "My tasks",
    no_rating: "No rating",
    nobody: "Nobody",
    open_whatsapp: "Open whatsapp",
    outgoing_calls_through: "Outgoing calls through",
    overdue: "Overdue",
    patient_has_no_future_visits_continue: "No future visits found for the patient. Do you want to continue?",
    patient_not_derived: "Patient not found",
    patient_not_derived_from_phone: "Call from {phone} - patient not found",
    plan_treatments: "Plan treatments",
    planned: "Planned",
    publish: "Publish",
    published: "Published",
    rating: "Rating",
    recall: "Call date",
    recall_on_lead: "Recall on lead '{title}'",
    record_myself: "Submit",
    record_on_visit: "Create a visit",
    recorded_on_visit: "Recorded on visit",
    responsible: "Responsible person",
    responsible_on_call: "Responsible on call",
    responsible_on_other_leads_not_created_manually: "Responsible for other leads not created manually",
    sales_funnel: "Sales funnel",
    search_by_name: "Search by name",
    search_by_phone: "Search by phone",
    see_later: "See later",
    sipuni: "Sipuni",
    sipuni_integration_instructions: "To integrate Dentist Plus with Sipuni you need: <br>1. create an account in Sipuni<br>2. in the Sipuni personal account in the API section, generate an integration key, click 'activate the service' in the 'Call to number' and 'PBX events' items, and also in the 'PBX events' item specify the 'URL of the receiving script':<br>< span class='text-primary'>{url}</span><br>3. in the field below, indicate users from the clinic staff who will use the sipuni functionality, and fill in the remaining fields below:",
    soon: "Soon",
    stage: "Stage",
    stages: "Stages",
    start_period: "Starting date",
    system_number: "Number in the system",
    task: "Tasks",
    task_date: "Task date",
    task_type: "Task type",
    tasks_and_calls: "Tasks and calls",
    tilda_integration_instructions: "To receive webhooks from forms on your Tilda website and generate leads in the dentist-plus system, you need the following:<br />1. In your Tilda account, go to Site Settings → Forms → Webhook<br/>2. In the Webhook URL field, enter <span class='text-primary'>{url}</span><br>3. In the API METHOD field select 'HEADER', in the 'Field name' fill in 'API-KEY', and in the 'Api key' field itself copy the key that you can generate below.<br />4. In advanced settings, activate 'Send Cookies'.<br />5. The following fields from the forms will be automatically filled in the dentist-plus system: <b>name, phone, email, comment</b>.",
    treatment_plan_signed: "Treatment plan signed",
    treatment_refused: "Treatment refused",
    treatment_started: "Treatment started",
    turn_on_chat_panel: "Turn on chat panel",
    uis: "UIS",
    uis_integration_instructions: "To integrate Dentist Plus with UIS you need: <br>1. create an account in UIS<br>2. in the UIS personal account, open the settings of the desired user (for example, Administrator) and in the API Functionality Access section, activate the 'Use API Key' setting<br/>3. In the 'Notifications' menu item, create 2 notifications: for waiting response and the end of a conversation. In both cases, activate the HTTP notification method and in the URL field specify the link:<br><span class='text-primary'>{url}</span><br>4. in the field below, indicate users from the clinic staff who will use the uis functionality, and fill in their virtual numbers (for receiving and initializing calls):",
    unpublish: "Unpublish",
    user_add_id: "Author",
    view: "View",
    visit_confirm: "Visit confirm",
    without_tasks: "Without tasks",
    write_in_messenger: "Write in messenger",
    you_also_can_use_link: "You can also use the link to the form directly",
}
