export default {
    add_task: "Tapşırıq əlavə et",
    add_patient: "Xəstə əlavə et",
    calls: "Zənglər",
    chat_panel: "Söhbət paneli",
    clinic_id: "Klinika ID",
    expenses: "Xərclər",
    high: "Yüksək",
    low: "Aşağı",
    medium: "Orta",
    no_notifications: "Bildiriş yoxdur",
    read_all: "Hamısını oxu",
    tasks: "Tapşırıqlar"
}
