import general from './az_general'
import header from './az_header'
import sidebar from './az_sidebar'
import calendar from './az_calendar'
import warehouses from './az_warehouses'
import patients from "./az_patients"
import settings from "./az_settings"
import services from "./az_services"
import staff from "./az_staff"
import accounting from "./az_accounting"
import reports from "./az_reports"
import templates from "./az_templates"
import cashdesk from "./az_cashdesk"
import planning from "./az_planning"
import crm from "./az_crm"
import egisz from "./az_egisz"
import marketing from "./az_marketing"
import insurance from "./az_insurance"
import tags from "./az_tags"
import referral from "./az_referral"
import nontranslated from "./az_nontranslated"

export default {
    ...general,
    ...header,
    ...sidebar,
    ...calendar,
    ...warehouses,
    ...patients,
    ...services,
    ...staff,
    ...accounting,
    ...reports,
    ...templates,
    ...cashdesk,
    ...planning,
    ...settings,
    ...crm,
    ...egisz,
    ...marketing,
    ...insurance,
    ...tags,
    ...referral,
    ...nontranslated
}
