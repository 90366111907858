<template>
  <section id="payments_general" class="payments_general">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('payments') }}</h1>
          </div>
          <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
            <SearchTable/>
          </div>
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto order-md-4 order-1 table-view-navigator" v-if="['superadmin'].includes(role)">
            <DataTableExcel :superadmin="true"/>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator"
               v-if="['superadmin', 'supermanager', 'account_manager'].includes(role)">
            <AddObject module="superadmin"/>
          </div>
        </div>
      </div>
    </transition>
    <div class="sub-menu no-print">
      <router-link v-for="(m, mind) in menu"
                   :to="{ name: m.to }"
                   :key="`superadmin-link-${mind}}`">
        {{ $t(m.title) }}
      </router-link>
    </div>
    <router-view/>
  </section>
</template>

<script>
import SearchTable from "@/components/parts/datatable/SearchTable"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"

export default {
  name: "SuperAdminPaymentsGeneral",
  components: {
    DataTableFilterButton,
    DataTableExcel,
    AddObject,
    SearchTable,
  },
  data() {
    return {}
  },
  computed: {
    menu() {
      if(this.role === 'account_manager') {
        return [
          { to: 'SuperAdminPayments', title: 'payments' },
        ]
      }
      return [
        { to: 'SuperAdminPayments', title: 'payments' },
        { to: 'SuperAdminPayMethods', title: 'pay_methods' },
        { to: 'SuperAdminBillingCurrencyRates', title: 'currency_rates' },
        { to: 'SuperAdminBillingServices', title: 'services' },
        { to: 'SuperAdminBonuses', title: 'bonuses' },
      ]
    },
    role() {
      return this.$store.state.auth.user.role
    },
  }
}
</script>
