export const taskAdapter =
    ({
       id,
       title,
       description,
       importance,
       users,
       patient,
       deadline,
       start,
       files,
       phone,
       filesToDelete,
       task_type,
       group_id,
       lead_id,
       patient_canban_id,
     }) => ({
      id: id ? id : null,
      title: title ?? null,
      description: description ?? null,
      importance: importance ?? null,
      user_id: users && users.length ? users[0].id : null,
      user_ids: users.map(u => u.id),
      patient_id: patient ? patient.id : null,
      deadline: deadline ? deadline : null,
      start: start ? start : null,
      phone: phone ? phone : null,
      files: processFiles(files),
      filesToDelete: filesToDelete ?? null,
      task_type: task_type ? task_type : 'task',
      group_id: group_id ? group_id : null,
      lead_id: lead_id ? lead_id : null,
      patient_canban_id: patient_canban_id ? patient_canban_id : null,
    })


function processFiles(files) {
  let arr = []
  if(files) {
    files.forEach(file => {
      if(!file.id) {
        arr.push(file)
      }
    })
  }
  return arr
}

