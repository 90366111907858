<template>
  <section id="countries" class="countries">
    <div>
      <DataTable :options="options"
                 module="superadmin"/>
    </div>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/country"
import { mapState } from "vuex"

export default {
  name: "SuperAdminCountries",
  components: {
    DataTable
  },
  head() {
    return {
      title: this.$t('users'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
