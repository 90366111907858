export default {
	fill_form_with_data_of_whom_you_recommend_and_we_will_contact_you: "Заполните форму с данными того, кому рекомендуете, и мы c Вами свяжемся:",
	fill_form_yourself: "Заполните форму самостоятельно!",
	how_referral_system_works: 'Как работает реферальная система?',
	immediately_after_first_payment_of_your_referral: "Сразу после первого платежа вашего реферала",
	let_your_friend_scan_qr: "Дайте отсканировать QR-код вашему другу",
	let_your_friend_scan_qr_below: "Дайте отсканировать QR-код ниже своему другу",
	or: "или",
	receive_payment_from_our_manager_on_your_card: "Получите выплату от нашего менеджера на Вашу карту.",
	receive_reward_for_first_payment: "Получайте <span class='text-primary'>{reward}</span> {currency} за первую оплату вашего реферала.",
	recommend_dentist_plus_to_your_friends: "Рекомендуйте Dentist + своим знакомым через партнерскую программу и получите вознаграждение:",
	recommend_dentist_plus_to_your_friends_with_any_method: "Рекомендуйте программу Dentist + своим знакомым с помощью любого на выбор способа:",
	referral_system_for_users: 'Реферальная система для пользователей',
	start_earning_right_now: 'Начните зарабатывать прямо сейчас!'
}
