export default {
    "1_month_ago": "1 aydan az",
    "3_months_ago": "3 aydan az",
    "6_months_ago": "6 aydan az",
    "12_months_ago": "12 aydan az",
    "above_1_month": "1 aydan çox",
    "above_2_months": "2 aydan çox",
    "above_3_months": "3 aydan çox",
    "above_6_months": "6 aydan çox",
    "above_1_year": "1 ildən çox",
    add_patients: "Xəstələr əlavə et",
    add_standard: "Standartları əlavə et",
    after_service: "Xidmətdən sonra",
    amount_of_patients: "Xəstələrin sayı",
    are_you_sure_you_want_to_delete_mailing: "Siz həqiqətən bu göndərişi silmək istəyirsiniz?",
    birth_day: "Doğum günü",
    birth_month: "Doğum ayı",
    cannot_be_delivered: "Çatdırıla bilməz",
    channel: "Kanal",
    channels: "Kanallar",
    complex_status_changed: "Müalicə planında kompleksin statusu dəyişdirildi",
    create_mailing: "Göndəriş yarat",
    do_not_send_at_night_time: "Gecə vaxtı göndərməyin (22:00 - 07:00)",
    does_not_contain: "Əhatə etmir",
    duplicate: "Kopyala",
    given_time: "Müəyyən edilmiş vaxt",
    has_future_visit: "Gələcək tarixlərdə viziti var",
    has_tags: "Etiketləri var",
    has_treatment_plan_with_status: "Müalicə planı statusu var",
    has_visit_with_status: "Statusla viziti var",
    immediately_after_creating: "Yaradıldıqdan dərhal sonra",
    launch_mailing: "Göndərişi başlat",
    mailing: "Göndəriş",
    mailing_date: "Göndəriş tarixi",
    mailing_report: "Göndəriş hesabatı",
    mailings: "Göndərişlər",
    marketing: "Marketinq",
    marketing_mailing: "Göndəriş",
    no_push_token: "Token yoxdur",
    not_enough_balance: "Hesabda kifayət qədər vəsait yoxdur",
    of_days: "gün",
    of_hours: "saat",
    passed_since_last_visit: "Son vizitdən keçib",
    plan_status_changed: "Müalicə planının statusu dəyişdirildi",
    push_notification: "Push-bildiriş",
    push_notifications: "Push-bildirişlər",
    ready: "Hazırdır",
    retry: "Təkrar cəhd edin",
    save_filter: "Filteri yadda saxla",
    send_again: "Yenidən göndər",
    send_date: "Göndəriş tarixi",
    send_info: "Göndəriş məlumatı",
    sent_slash_error: "Göndərildi / Xəta",
    since_last_visit: "Son vizitdən sonra",
    sms_message: "SMS mesajı",
    stop_mailing: "Göndərişi dayandır",
    tags_for_marketing_mailing: "Məlumatın avtomatik çıxarılması üçün etiketlər:",
    time: "Vaxt",
    tokens: "Tokenlər",
    too_many_attempts: "Çoxlu göndəriş cəhdləri",
    total_in_group: "Qrupda cəmi",
    treatment_plan_created: "Yeni müalicə planı yaradıldı",
    visit_time_changed: "Vizitin vaxtı dəyişdirildi",
    users_selected: "Seçilmiş istifadəçilər",
    wrong_credentials: "Göndəriş xidmətinin avtorizasiya məlumatları yanlışdır",
    wrong_phone_number: "Telefon nömrəsi yanlışdır",

    january: "Yanvar",
    february: "Fevral",
    march: "Mart",
    april: "Aprel",
    may: "May",
    june: "İyun",
    july: "İyul",
    august: "Avqust",
    september: "Sentyabr",
    october: "Oktyabr",
    november: "Noyabr",
    december: "Dekabr",
}
