export default {
	fill_form_with_data_of_whom_you_recommend_and_we_will_contact_you: "Fill out the form with the details of the person you recommend, and we will contact you:",
	fill_form_yourself: "Fill out the form yourself!",
	how_referral_system_works: 'How referral system works?',
	immediately_after_first_payment_of_your_referral: "Immediately after your referral's first payment",
	let_your_friend_scan_qr: "Let your friend scan the QR code below",
	let_your_friend_scan_qr_below: "Let your friend scan the QR code below",
	or: "or",
	receive_payment_from_our_manager_on_your_card: "Receive payment from our manager to your card.",
	receive_reward_for_first_payment: "Get <span class='text-primary'>{reward}</span> {currency} for your referral's first payment.",
	recommend_dentist_plus_to_your_friends: "Recommend Dentist + to your friends through the affiliate program and get a reward:",
	recommend_dentist_plus_to_your_friends_with_any_method: "Recommend Dentist+ to your friends using any method you choose:",
	referral_system_for_users: 'Referral system for users',
	start_earning_right_now: 'Start earning right now!'
}
