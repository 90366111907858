export default {
    f37u78: "Форма 037/У-88",
    f43kaz: "Форма 043/КАЗ",
    f43rus: "Форма 043/РФ",
    f43ukr: "Форма 043/У",
    f58kaz: "Форма 058/КАЗ",
    accept_another_payment: "Принять еще оплату",
    activity: "Активность",
    add_complex: "Добавить комплекс",
    add_diagnosis: "Добавить диагноз",
    add_question: "Добавить вопрос",
    add_service: "Добавить услугу",
    add_tooth_jaw: "Добавить зуб / челюсть",
    add_visit: "Добавить визит",
    added_by: "Кем добавлен",
    additional_discount: "Дополнительная скидка",
    age: "Возраст",
    agreement: "Согласие",
    akt_of_services_for_period: "Акт оказанных услуг за период",
    akt_print: "Печать акта",
    allergic_reactions: "Аллергические реакции",
    allergy_diagnosis: "Аллергологический диагноз",
    anamnesis: "Стоматологический анамнез",
    applied: "применен",
    applied_complex: "Примененный комлекс",
    apply_to_teeth: "Применить к зубам",
    apply_to_whole_tooth: "Применить ко всему зубу",
    apply_treatment_template: "Применить шаблон лечения",
    are_you_sure_to_set_plan: "Вы уверены, что хотите привязать карточку лечения к плану - заполненные поля карточки могут быть перезаписаны?",
    are_you_sure_you_want_to_delete_document: "Вы уверены, что хотите удалить документ?",
    are_you_sure_you_want_to_delete_payment: "Вы уверены, что хотите удалить оплату?",
    are_you_sure_you_want_to_delete_scan: "Вы уверены, что хотите удалить скан?",
    are_you_sure_you_want_to_delete_status: "Вы уверены, что хотите удалить статус?",
    are_you_sure_you_want_to_delete_transaction: "Вы уверены, что хотите удалить транзакцию?",
    are_you_sure_you_want_to_delete_user: "Вы уверены, что хотите удалить пользователя?",
    are_you_sure_you_want_to_delete_users: "Вы уверены, что хотите удалить пользователей?",
    ask_result_on_saving: "Запрашивать при сохранении результат визита",
    assistant: "Ассистент",
    attach_new_user: "Привязать нового пользователя",
    author: "Автор",
    available: "Доступно",
    baby_teeth: "Молочные зубы",
    balance_refill: "Пополнение депозита",
    balance_writeoff: "Списание депозита",
    birthdays: "Дни рождения",
    bite: "Прикус",
    bottom_baby_teeth: "Нижние молочные зубы",
    bottom_jaw: "Нижняя челюсть",
    bottom_molars: "Нижние коренные зубы",
    by_doctors: "По врачам",
    by_complexes: "По комплексам",
    call: "Позвонить",
    came: "Пришел",
    came_from: "Откуда пришел",
    can_be_paid_with_bonuses: "Можно оплатить бонусами",
    cancel_initiator: "Инициатор отмены",
    cancelled: "Отменен",
    card: "Карточка",
    card_number: "Номер карточки",
    card_transfer: "Перевод на карту",
    cash: "Наличные",
    cashless: "Безнал",
    choose_document_type_and_its_parameters: "Выберите тип документа и его параметры",
    choose_patient: "Выберите пациента",
    choose_signer: "Выберите подписанта",
    choose_treatment_template: "Выберите шаблон лечения",
    client: "Пациент",
    client_document: "Документ пациента",
    client_id: "Пациент",
    client_note: "Примечание",
    client_service: "Клиентский сервис",
    code: "Код",
    color: "Цвет",
    combine_user_note: "Выбрав пациента и нажав \"объединить\", вы перенесете все данные по визитам, оплатам, диагнозам и тд в карточку выбранного пациента. Карточка текущего пациента будет удалена. Процедуру объединения нельзя будет отменить.",
    comment_to_phone: "Комментарий к телефону",
    comment_to_phone_2: "Комментарий к доп. телефону",
    complaints: "Жалобы",
    complex: "Комплекс",
    complexes: "Комплексы",
    condition: "Состояние",
    confirm_tag_deletion: "Вы уверены, что хотите удалить метку?",
    confirmed: "Подтверждена",
    confirmed_male: "Утвержден",
    contract: "Договор",
    contract_agreement: "Договор согласия",
    contract_general: "Общий договор",
    contract_print: "Печать договора",
    contract_services: "Договор предоставления услуг",
    consultation: "Консультация",
    create_diagnosis: "Создать диагноз",
    creation_date: "Дата создания",
    date: "Дата",
    date_add: "Создано",
    date_from: "Дата от",
    date_of_birth: "Дата рождения",
    date_to: "Дата до",
    dates_not_selected: "Даты не выбраны",
    debt: "Долг",
    debt_cancellation: "Отмена долга",
    delete_complex: "Удалить комплекс",
    delete_visit: "Удалить визит",
    department_code: "Код подразделения",
    deposit: "Депозит",
    description: "Описание",
    desired_date_from: "Желаемая дата от",
    desired_date_to: "Желаемая дата до",
    desired_time_from: "Желаемое время от",
    desired_time_to: "Желаемое время до",
    detailed_information: "Подробная информация",
    diagnoses: "Диагнозы",
    diagnosis: "Диагноз",
    did_not_come: "Не пришел",
    discount: "Скидка",
    disease_anamnez: "Анамнез заболевания (развитие настоящего заболевания)",
    disease_development: "Развитие настоящего заболевания",
    disease_history: "История болезни",
    diseases_passed_and_current: "Перенесенные и сопутствующие заболевания",
    doctor: "Врач",
    document: "Документ",
    document_number: "Номер документа",
    document_type: "Тип документа",
    drag_file_here_or_select: "Перенесите сюда файл или выберите",
    enter_the_title: "Введите название",
    epikriz: "Эпикриз",
    examination_plan: "План обследования",
    f043: "Ф 043",
    family_account: "Семейный счет",
    female: "Женщина",
    female_short: "Жен",
    fill_anamnez_from_previous_visits: "Заполнить анамнез из предыдущих визитов",
    fill_balance: "Пополнить депозит",
    filling: "Пополнение",
    finish_visit: "Завершить прием",
    fio: "ФИО",
    first_visit: "Первичный визит",
    fiscal_cheque: "Фискальный чек",
    for_female_patients: "Для пациенток",
    formula: "Формула",
    foto_link_note: "Помимо загрузки фотографии, можно указать публичную ссылку на изображение",
    from_account: "Со счета",
    full_card: "Полная карточка",
    gender: "Пол",
    general_info: "Основная информация",
    hygiene_index: "Индекс гигиены",
    iin: "ИИН",
    in_chair: "В кресле",
    in_process: "В процессе",
    include_in_f043: "Включить в форму 043",
    include_in_f058: "Включить в форму 058",
    inspection: "Внешний осмотр",
    insurance: "Страховка",
    invoice_print: "Печать счета",
    importance: "Важность",
    important: "Важно",
    inn: "ИНН",
    is_deleted: "Удален",
    issue_date: "Дата выдачи",
    issued_by: "Кем выдано",
    jaws: "Челюсти",
    kpu_index: "Индекс КПУ",
    last_visit: "Последний визит",
    left_due: "Остаток",
    life_anamnez: "Анамнез жизни",
    link: "Ссылка",
    link_to_call_record: "Ссылка на запись разговора",
    listen: "Послушать",
    live_feed: "Живая лента",
    lost: "Утерян",
    make_payment: "Произвести оплату",
    male: "Мужчина",
    male_short: "Муж",
    manual_status: "Ручной статус",
    medication: "Лечение",
    middle_name: "Отчество",
    mkb_code: "Код МКБ",
    move_to_visit: "Перейти к визиту",
    mphone: "Телефон",
    ndfl_statement: "Справка НДФЛ",
    ndfl_statement_new: "Справка НДФЛ (новая)",
    new: "Новый",
    new_patient: "Первичный пациент",
    next_visit: "Следующий визит",
    no_attachment: "Нет загрузки",
    no_complexes: "Нет комплексов",
    no_teeth_in_the_record: "У записи не указано ни одного зуба",
    norm: "Норма",
    not_confirmed: "Не подтверждена",
    note: "Примечание",
    notes: "Примечания",
    objectively: "Объективно",
    on_week: "На неделю",
    or_write_other_reason: "или укажите другую причину",
    oral_cavity: "Ротовая полость",
    ortodentic_formula: "Ортодонтическая формула",
    ortodentic_formula_ru: "Ортодонтическая формула (РФ)",
    other: "Прочее",
    overpayment: "Переплата",
    paid: "Оплачено",
    passive: "Пассивный",
    passport: "Паспорт",
    passport_data: "Паспортные данные",
    passport_number: "Номер паспорта",
    password_validation_error: "Пароль должен иметь длину не менее 8 символов, содержать хотя бы одну заглавную букву, хотя бы одну цифру и хотя бы один специальный символ.",
    patient: "Пациент",
    patient_condition_id: "Состояние",
    patient_deleted: "Пациент удален",
    patient_fio: "ФИО пациента",
    patient_has_important_notes: "У пациента важных пометок: {cnt}",
    patient_number: "Номер пациента",
    patient_phone: "Телефон пациента",
    patient_source: "Откуда пришел",
    payment: "Оплата",
    payment_accepted: "Оплата принята",
    payment_date: "Дата оплаты",
    payment_form: "Форма оплаты",
    payment_history: "История оплат",
    payment_processing: "Проведение оплаты",
    payment_type: "Способ оплаты",
    payments: "Оплаты",
    permanent_teeth: "Постоянные зубы",
    personal_data: "Персональные данные",
    pesel: "PESEL",
    phone_2: "Доп. телефон",
    pin: "Закрепить",
    pinned: "Прикреплено",
    plan_fact: "План / Факт",
    plan_notification: "Запланировать уведомление",
    planned_result: "Планируемый результат",
    planned_send_date: "Планируемая дата отправки",
    planned_services: "Планируемые процедуры",
    planned_treatment: "Планируемое лечение",
    preliminary: "Предварительный",
    price_price: "Прайс",
    prices_price: "Прайсы",
    print_empty: "Печать незаполненной",
    process: "Провести",
    questionnaire: "Анкета",
    quick_card: "Быстрая карточка",
    receipt: "Квитанция",
    recommendations: "Рекомендации",
    record: "Запись",
    record_not_available: "Запись не доступна",
    recorded: "Записан",
    refresh_prices: "Обновить цены",
    region: "Область",
    regular_client: "Постоянный пациент",
    rejected: "Отказался",
    reopen_visit: "Повторно открыть приём",
    representative: "ФИО представителя",
    representative_address: "Адрес представителя",
    representative_document: "Реквизиты документа, удостоверяющего право представлять пациента",
    representative_document_number: "Номер документа представителя",
    representative_document_type: "Тип документа представителя",
    representative_id: "Представитель из списка пациентов",
    representative_inn: "ИНН представителя",
    representative_noun: "Представитель",
    representative_passport: "Номер паспорта представителя",
    representative_passport_data: "Паспортные данные представителя",
    representative_phone: "Телефон представителя",
    request_snils: "Запросить СНИЛС",
    request_snils_note: "Получение СНИЛС от ЕГИСЗ - для этого необходимо заполнить ФИО пациента, пол, дату рождения и паспортные данные (тип, серия, номер, дата выдачи и кем выдано)",
    restore: "Восстановить",
    result: "Результат",
    results_not_found: "Результатов не найдено",
    results_of_xray_and_lab_research: "Данные рентгеновских, лабораторных исследований",
    save_as_template: "Сохранить как шаблон",
    save_as_treatment_template: "Сохранить как шаблон лечения",
    save_and_close: "Сохранить и закрыть",
    scan: "Снимок",
    scans: "Снимки",
    select_document_type: "Выберите тип документа",
    select_visit: "Выберите визит",
    selected_teeth: "Выбранные зубы",
    send: "Отправить",
    send_mail: "Отправить письмо",
    send_marketing: "Отправлять маркетинговые рассылки",
    send_message: "Отправить сообщение",
    send_notifications: "Отправлять уведомления",
    send_password_to_patient: "Отправить пароль пациенту",
    send_try: "Попытка отправки",
    series: "Серия",
    service: "Услуга",
    services_payment_from: "Оплата оказанных услуг от",
    set_the_amount: "Укажите кол-во",
    size: "Размер",
    sms_available: "Доступно СМС",
    sms_sending: "Отправка СМС",
    snils: "СНИЛС",
    sorting_instructions: "Перетащите строки мышью, чтобы расположить их в нужном порядке",
    standard: "Стандарт",
    start_treatment: "Начать лечение",
    start_visit: "Начать прием",
    stopped: "Остановлено",
    subjectively: "Субъективно",
    sum_with_discount: "Сумма со скидкой",
    superadministrator: "Суперадминистратор",
    surname: "Фамилия",
    short_name: "Короткое название",
    symbols_amount: "Количество символов",
    tags_for_contract: "Тэги для автоматического вывода информации:",
    tags_for_document: "Тэги для автоматического вывода информации:",
    teeth: "Зубы",
    teeth_card: "Зубная карта",
    termometry: "Термометрия",
    there_were_no_payments: "Оплат пока не было",
    this_phone_is_in_system: "Данный номер уже имеется в базе",
    to_account: "На счет",
    to_pay_with_bonus: "Оплатить бонусами",
    to_whom: "Кому",
    tonometry: "Тонометрия",
    tooth: "Зуб",
    topic: "Тема",
    treatment_template: "Шаблон лечения",
    tooth_is_assigned: "Зуб не может быть удален - к нему уже привязаны услуги или диагнозы",
    tooth_formula: "Зубная формула",
    top_baby_teeth: "Верхние молочные зубы",
    top_jaw: "Верхняя челюсть",
    top_molars: "Верхние коренные зубы",
    to_add_diagnoses_you_need_to_select_teeth: "Чтобы добавить диагнозы, необходимо выбрать зубы",
    to_add_services_you_need_to_select_teeth: "Чтобы добавить услуги, необходимо выбрать зубы",
    total_paid: "Всего оплачено",
    total_paid_for_treatment_plan: "Оплачено услуг по плану лечения",
    total_services_selected: "Итого выбрано услуг",
    total_to_pay: "Итого к оплате",
    total_with_discount: "Итого со скидкой",
    transfer_between_patient_accounts: "Перевод между счетами пациента",
    transfer_whole_amount: "Перевести всю сумму",
    treating_doctor: "Лечащий врач",
    treatment: "Лечение",
    treatment_card: "Карточка лечения",
    treatment_complete: "Лечение завершено",
    treatment_plan: "План лечения",
    un: "шт.",
    unify: "Объединить",
    unify_card: "Объединить карточку",
    unpaid: "Неоплачено",
    unpin: "Открепить",
    upload_date: "Дата загрузки",
    used_medication: "Принимаемые лекарственные препараты",
    user_attached_to_family_account: "Пользователь привязан к семейному счету",
    users_attached_to_user_family_account: "Пациенты, привязанные к семейному счету пользователя",
    visit: "Визит",
    visit_date: "Дата визита",
    visit_description_doctor: "Описание визита врачом",
    visit_duration: "Длительность визита (в мин)",
    visit_finished: "Прием закончен",
    visit_in_progress: "Идет прием",
    visit_started: "Прием начат",
    visit_time: "Время визита",
    visits: "Визиты",
    waiting: "Ожидает",
    waiting_list: "Лист ожидания",
    with_formula: "С формулой",
    without_payment: "Без оплаты",
    write_reason: "Укажите причину",
    xray: "Рентген",
    years: "Лет",
    you_need_to_select_teeth: "Вам нужно выбрать зубы",
    zipcode: "Почтовый индекс",
}
