export default {
	fill_form_with_data_of_whom_you_recommend_and_we_will_contact_you: "Заповніть форму з даними того, кому рекомендуєте, і ми зв'яжемося з Вами:",
	fill_form_yourself: "Заповніть форму самостійно!",
	how_referral_system_works: 'Як працює реферальна система?',
	immediately_after_first_payment_of_your_referral: "Відразу після першого платежу вашого рефералу",
	let_your_friend_scan_qr: "Дайте QR-код відсканувати вашому другу",
	let_your_friend_scan_qr_below: "Дайте відсканувати QR-код нижче своєму другові",
	or: "або",
	receive_payment_from_our_manager_on_your_card: "Отримайте виплату від нашого менеджера на Вашу картку.",
	receive_reward_for_first_payment: "Отримуйте <span class='text-primary'>{reward}</span> {currency} за першу оплату реферелу.",
	recommend_dentist_plus_to_your_friends: "Рекомендуйте Dentist+ своїм знайомим через партнерську програму та отримайте винагороду:",
	recommend_dentist_plus_to_your_friends_with_any_method: "Рекомендуйте програму Dentist+ своїм знайомим за допомогою будь-якого на вибір способу:",
	referral_system_for_users: 'Реферальна система для користувачів',
	start_earning_right_now: 'Почніть заробляти зараз!'
}
