export default {
    "1_month_ago": "Less than a month",
    "3_months_ago": "Less than 3 months",
    "6_months_ago": "Less than 6 months",
    "12_months_ago": "Less than 12 months",
    "above_1_month": "More than 1 month",
    "above_2_months": "More than 2 months",
    "above_3_months": "More than 3 months",
    "above_6_months": "More than 6 months",
    "above_1_year": "More than a year",
    add_patients: "Add patients",
    add_standard: "Add standard",
    after_service: "After service",
    amount_of_patients: "Amount of patients",
    are_you_sure_you_want_to_delete_mailing: "Are you sure you want to delete mailing?",
    birth_day: "Birth day",
    birth_month: "Birth month",
    cannot_be_delivered: "Cannot be delivered",
    channel: "Channel",
    channels: "Channels",
    complex_status_changed: "Treatment plan complex status changed",
    create_mailing: "Create Mailing",
    do_not_send_at_night_time: "Do not send at night time (22:00 - 07:00)",
    does_not_contain: "Does not contain",
    duplicate: "Copy",
    given_time: "Target time",
    has_future_visit: "There is an appointment on future dates",
    has_tags: "Has tags",
    has_treatment_plan_with_status: "Has a treatment plan with a status",
    has_visit_with_status: "Has an appointment with a status",
    immediately_after_creating: "Immediately after creating",
    launch_mailing: "Launch Mailing",
    mailing: "Mailing",
    mailing_date: "Mailing date",
    mailing_report: "Mailing report",
    mailings: "Mailings",
    marketing: "Marketing",
    marketing_mailing: "Mailing",
    no_push_token: "Token is missing",
    not_enough_balance: "Low balance",
    of_days: "Days",
    of_hours: "Hours",
    passed_since_last_visit: "Passed since last visit",
    plan_status_changed: "Treatment plan status changed",
    push_notification: "Push-notification",
    push_notifications: "Push-notifications",
    ready: "Ready",
    retry: "Retry",
    save_filter: "Save filter",
    send_again: "Resend",
    send_date: "Sending date",
    send_info: "Sending information",
    sent_slash_error: "Sent / Error",
    since_last_visit: "Since last visit",
    sms_message: "SMS",
    stop_mailing: "Stop mailing",
    tags_for_marketing_mailing: "Tags for automatic display of information:",
    time: "Time",
    tokens: "Tokens",
    too_many_attempts: "Too many attempts",
    total_in_group: "Total in group",
    treatment_plan_created: "New treatment plan created",
    visit_time_changed: "Visit time changed",
    users_selected: "Users selected",
    wrong_credentials: "Invalid submission service credentials",
    wrong_phone_number: "Invalid phone number",

    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
}
