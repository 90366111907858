<template>
  <transition name="_fade" mode="out-in" >
    <div class="pb-4" v-if="loaded">
      <vue-excel-xlsx
          ref="excel"
          class="d-none"
          :data="transferToExcel ? transferToExcel(sorted) : sorted"
          :columns="columnsForExcel"
          :filename="'report'"
          :sheetname="'report'">
      </vue-excel-xlsx>
      <div class="mb-3" v-if="hiddenColumns && hiddenColumns.length">
        <span class="text-muted mr-2">{{ $t('hidden_columns') }}:</span>
        <span v-for="c in hiddenColumns"
              @click="toggleColumn(c)"
              class="tags-note tag-code pointer mr-2"
              :key="`hidden-col-${c}`">{{ $t(c) }}
        </span>
      </div>
      <div class="data-table-wrapper html-table-wrapper" :class="{ 'manycolumns': (columns.length - hiddenColumns.length) > 12 }">
        <div class="mb-3 text-muted" v-if="!hideRowsCounter && sorted">
          {{ $t('records') }}: {{ formatNumber(sorted.length) }}
        </div>
        <table class="table data-table" :class="{ 'more-button': needsMoreButton }">
          <thead>
          <tr :class="`order-${sort}`">
            <template v-for="f in columns">
              <th @click="resort(f)"
                  :class="[
                  {
                    sortable: f.sortable,
                    sorted: order === (f.sortable && f.sortable !== true ? f.sortable : f.field),
                    primary: primaryKey == f.field,
                    hidden: hiddenColumns.includes(f.field)
                  },
               `th-${f.field}`]"
                  v-if="!f.right_excludes || !rights.includes(f.right_excludes)"
                  class="data-table-th"
                  :key="`data-table-header-${f.field}`">
                <span v-if="!hiddenColumns.includes(f.field)">{{ f.label }}</span>
                <span v-if="canHideRows"
                      @click.stop="toggleColumn(f.field)"
                      class="toggle-column">
                  <EyeSvg v-b-tooltip.hover :title="f.label" v-if="hiddenColumns.includes(f.field)"/>
                  <NoEyeSvg v-if="!hiddenColumns.includes(f.field)"/>
                </span>
              </th>
            </template>
          </tr>
          </thead>
          <tbody>
          <tr class="html-table-search-tr" v-if="!hideWholeSearchPanel">
            <template v-for="f in columns">
              <th :key="`data-table-input-header-${f.field}`"
                  v-if="!f.right_excludes || !rights.includes(f.right_excludes)"
                  :class="{ hidden: hiddenColumns.includes(f.field) }">
                <template v-if="!hiddenColumns.includes(f.field)">
                  <select class="form-control sm mw-auto"
                          v-if="f.search_select || f.search_select_manual_options"
                          @change="selectChanged(f)"
                          v-model="searches[f.field]">
                    <option :value="null">-</option>
                    <option :value="o"
                            :key="`option-${f.field}-${oind}`"
                            v-for="(o, oind) in (f.search_select_manual_options ? f.search_select_manual_options : searchOptions[f.field])">
                      {{ f.format_translate ? $t(o) : o }}
                    </option>
                  </select>
                  <input v-else-if="!f.hide_search"
                         class="form-control sm mw-auto"
                         v-model="searches[f.field]"/>
                </template>
              </th>
            </template>
          </tr>
          <tr v-for="(l, lind) in sorted"
              :key="`data-table-${lind}`">
            <td v-if="l.wholerow"
                class="font-weight-bold pt-5"
                :colspan="columns.length">{{ l.wholerow }}</td>
            <template v-else
                      v-for="f in columns">
              <td :class="[{ hidden: hiddenColumns.includes(f.field) }, f.tabletdclass]"
                  v-if="(!f.right_excludes || !rights.includes(f.right_excludes)) && l[f.field] !== '[tdskip]'"
                  :data-title="f.label"
                  :rowspan="f.rowspan ? f.rowspan : 1"
                  :key="`data-table-${lind}-${f.field}`">
                <component :is="(f.tdcomponent && tdcomponents[f.tdcomponent]) ? tdcomponents[f.tdcomponent] : 'TDComponent'"
                           v-if="!hiddenColumns.includes(f.field)"
                           :object="l"
                           :field="f"/>
              </td>
            </template>
            <MobileMoreArrow v-if="needsMoreButton"/>
          </tr>
          <tr v-if="(!sorted || sorted.length < 1) && !loading">
            <td :colspan="columns.length">{{ $t('no_data_available') }}</td>
          </tr>
          </tbody>
          <tfoot v-if="Object.keys(totals).length > 0">
          <tr>
            <template v-for="f in columns">
              <td v-if="!f.right_excludes || !rights.includes(f.right_excludes)"
                  :data-title="f.label"
                  class="nowrap"
                  :class="{ hasData: !!totals[f.field], hidden: hiddenColumns.includes(f.field) }"
                  :key="`totals-${f.field}`">
                <template v-if="totals[f.field]">
                  <component v-if="f.totalscomponent && tdcomponents[f.totalscomponent]"
                             :is="tdcomponents[f.totalscomponent]"
                             :object="totals"
                             :field="f"/>
                  <div v-else>{{ formatNumber(totals[f.field], true) }}</div>
                </template>
              </td>
            </template>
          </tr>
          <slot name="dt-footer"/>
          </tfoot>
        </table>
        <!--        <Paginator v-if="paginator" :paginator="paginator"/>-->
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex"
import MobileMoreArrow from "@/components/parts/datatable/MobileMoreArrow"
import TDComponent from "@/components/parts/datatable/TDComponent"
import EyeSvg from '@/assets/svg-vue/form/eye.svg'
import NoEyeSvg from '@/assets/svg-vue/form/noeye.svg'
import { formatNumber } from "@/extensions/filters/filters"

export default {
  name: "DataTableHtml",
  components: {
    TDComponent,
    MobileMoreArrow,
    EyeSvg,
    NoEyeSvg
  },
  props: {
    rows: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    params: {
      type: Object,
    },
    initialOrder: {
      type: String,
      required: true
    },
    initialSort: {
      type: String,
      required: true
    },
    hideRowsCounter: {
      type: Boolean,
      default: false
    },
    hideWholeSearchPanel: {
      type: Boolean,
      default: false
    },
    canHideRows: {
      type: Boolean,
      default: true
    },
    transferToExcel: {
      type: Function,
      default: null
    },
    excelColumnsAdjustment: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      order: 'id',
      sort: 'asc',
      sorted: [],
      searches: {},
      searchOptions: {},
      totals: {},
      loaded: false,
      hiddenColumns: window.localStorage.getItem(this.$route.name+'_hiddencolumns'),
    }
  },
  mounted() {
    this.hiddenColumns = this.hiddenColumns ? JSON.parse(this.hiddenColumns) : []
    this.loaded = true
  },
  methods: {
    formatNumber,
    load() {
      this.sorted = this.copyObject(this.rows)
      for(let s in this.searches) {
        const column = this.columns.find(col => col.id === s)
        let q = this.searches[s]
        if(q) {
          this.sorted = this.sorted.filter(function(element) {
            if(q.length > 1) {
              let firstTwoSymbols = q.substr(0, 2)
              if(firstTwoSymbols == '>=') {
                return parseInt(element[s]) >= parseInt(q.replace('>=', ''))
              }
              if(firstTwoSymbols == '<=') {
                return parseInt(element[s]) <= parseInt(q.replace('<=', ''))
              }
            }
            if(q[0] == '>') {
              return parseInt(element[s]) > parseInt(q.replace('>', ''))
            }
            if(q[0] == '<') {
              return parseInt(element[s]) < parseInt(q.replace('<', ''))
            }
            if(!element[s]) return false
            let string = element[s].toString()
            if(!string) return false
            if(column && column.search_select_strict) {
              return string.toLowerCase() == q.toLowerCase()
            }
            return string.toLowerCase().includes(q.toLowerCase())
          })
        }
      }
      if(this.params) {
        for(let param in this.params) {
          let condition = this.params[param]
          this.sorted = this.sorted.filter(function(element) {
            if(!element[param]) return false
            let string = element[param].toString()
            if(!string) return false
            return condition.includes(string)
          })
        }
      }
      this.columns.forEach(column => {
        if(column.totals) {
          this.$set(this.totals, column.field, this.sorted.reduce((a, b) => {
            let val = b[column.field]
            if(Array.isArray(val)) {
              val.forEach((item, index) => {
                let n = parseFloat(item)
                if(isNaN(n)) n = 0
                if(!Array.isArray(a)) a = [];
                a[index] = (a[index] ?? 0) + n
              })
              return a
            } else {
              if(val && typeof val === 'string' && val.includes('<')) {
                val = this.tagsValuesToTotal(val, column.removeSubtotals)
              }
              let n = parseFloat(val)
              if(isNaN(n)) { n = 0 }
              return a + n
            }
          }, 0))
        }
        if(column.totalsFn) {
          this.$set(this.totals, column.field, column.totalsFn(this.totals))
        }
      })
      if(!this.sorted) return
      if(!this.order) return
      if(this.sort === 'asc') {
        this.sorted.sort((a, b) => (a[this.order] > b[this.order]) ? 1 : -1)
      } else {
        this.sorted.sort((a, b) => (a[this.order] < b[this.order]) ? 1 : -1)
      }
    },
    resort(f) {
      if(!f.sortable) return
      const sortable_field = f.sortable === true ? f.id : f.sortable
      if(this.order === sortable_field) {
        this.sort = this.sort === 'asc' ? 'desc': 'asc'
      } else {
        this.sort = 'asc'
      }
      this.order = sortable_field
      this.load()
    },
    tagsValuesToTotal(htmlString, removeSubtotals = false) {
      let res = 0
      const regex = /<[^>]*>([^<]*)<\/[^>]*>/g
      let match
      while ((match = regex.exec(htmlString)) !== null) {
        const val = Number(match[1].trim().replace(/\s/g, ''))
        if(val) res += val
      }
      if(res > 0 && removeSubtotals) {
        res = res / 2
      }
      return res
    },
    loadDictionaries() {
      this.order = this.initialOrder
      this.sort = this.initialSort
      this.columns.forEach(column => {
        if(column.search_select) {
          let arr = []
          if(column.search_doctor_one && this.rows) {
            this.rows.forEach(row => {
              let values = row[column.field].split(', ')
              arr = arr.concat(values)
            })
            arr = [...new Set(arr)]
          } else {
            arr = this.rows ? [...new Set(this.rows.map(row => row[column.field]))] : []
          }
          arr = arr.filter(n => n) // to remove empty strings
          this.$set(this.searchOptions, column.field, arr)
        }
      })
    },
    selectChanged(field) {
      if(field.changes_backend) {
        this.$emit('loadReport', this.searches)
      }
    },
    excel() {
      this.$refs.excel.$el.click()
    },
    toggleColumn(id) {
      const index = this.hiddenColumns.indexOf(id)
      if (index > -1) {
        this.hiddenColumns.splice(index, 1)
      } else {
        this.hiddenColumns.push(id)
      }
      window.localStorage.setItem(this.$route.name+'_hiddencolumns', JSON.stringify(this.hiddenColumns))
    }
  },
  watch: {
    searches: {
      handler() {
        this.load()
      },
      deep: true,
    },
    rows: {
      handler() {
        this.loadDictionaries()
        this.load()
      },
      immediate: true
    },
    params: {
      handler() {
        this.load()
      },
      immediate: true
    },
    search() {
      // if(this.timer) {
      //   clearTimeout(this.timer)
      // }
      // this.timer = setTimeout(() => {
      //   this.page = 1
      //   this.load()
      // }, 250)
    },
    counter() {
      this.load()
    },
  },
  computed: {
    ...mapState({
      search: state => state.datatable.search,
      counter: state => state.datatable.dataTableCounter,
      // filters: state => state.datatable.filters,
      loading: state => state.dom.loading,
      rights: state => state.auth.rights,
    }),
    mobile() {
      return window && window.innerWidth < 768
    },
    needsMoreButton() {
      if(!this.columns) return false
      return this.columns.length > 3
    },
    tdcomponents () {
      const components = require.context(`@/components/parts/datatable/td/`, true, /\.vue$/i)
      let arr = {}
      components.keys().map(x => {
        arr[x.split('/').pop().split('.')[0]] = components(x).default
      })
      return arr
    },
    columnsForExcel() {
      let cols = this.columns.map(col => {
        col.field = col.field ? col.field.toString() : ''
        return col
      })
      if(this.excelColumnsAdjustment) {
        cols = this.excelColumnsAdjustment(cols)
      }
      return cols
    }
  },
}
</script>

<style scoped lang="scss">
.toggle-column {
  position: absolute;
  right: 5px;
  top: 15px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  //background: $label-color;
  background: $gray-icon;
  display: none;
  svg {

    width: 10px;
    height: 10px;
    fill: #fff;
  }
}
.data-table-th {
  &:hover, &.hidden {
    .toggle-column {
      display: inline-flex;
      svg {
        display: block;
      }
    }
  }
}
th.hidden, td.hidden {
  width: 0;
  overflow: hidden;
  display: none;
  //font-size: 0;
}
.data-table {
  position: relative;
  thead {
    position: relative;
    th {
      position: sticky;
      top: 0;
    }
  }
}
</style>
