export default {
	fill_form_with_data_of_whom_you_recommend_and_we_will_contact_you: "Tövsiyə etdiyiniz şəxsin təfərrüatları ilə formanı doldurun, biz sizinlə əlaqə saxlayaq:",
	fill_form_yourself: "Formanı özünüz doldurun!",
	how_referral_system_works: 'Referans sistemi necə işləyir?',
	immediately_after_first_payment_of_your_referral: "Referalınızın ilk ödənişindən dərhal sonra",
	let_your_friend_scan_qr: "Dostunuza QR kodunu skan etməyə icazə verin",
	let_your_friend_scan_qr_below: "Dostunuza aşağıdakı QR kodu skan etməyə icazə verin",
	or: "və ya",
	receive_payment_from_our_manager_on_your_card: "Menecerimizdən kartınıza ödəniş alın.",
	receive_reward_for_first_payment: "Referalınızın ilk ödənişi üçün <span class='text-primary'>{reward}</span> {currency} alın.",
	recommend_dentist_plus_to_your_friends: "Tərəfdaşlıq proqramı vasitəsilə dostlarınıza Diş Həkimi + tövsiyə edin və mükafatlar alın:",
	recommend_dentist_plus_to_your_friends_with_any_method: "Seçdiyiniz hər hansı üsuldan istifadə edərək Dentist + proqramını dostlarınıza tövsiyə edin:",
	referral_system_for_users: 'İstifadəçilər üçün müraciət sistemi',
	start_earning_right_now: 'Elə indi pul qazanmağa başlayın!'
}
