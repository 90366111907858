<template>
  <section id="bonuses"
           class="bonuses pt-4">
    <div class="row align-items-end mb-3">
      <div class="col">
        <div class="container ml-0 px-0">
          <div class="row align-items-end">
            <div class="col-md-3">
              <DateGroup :label="$t('date_from')"
                         class="mb-0"
                         :required="true"
                         v-model="from"/>
            </div>
            <div class="col-md-3">
              <DateGroup :label="$t('date_to')"
                         :required="true"
                         class="mb-0"
                         v-model="to"/>
            </div>
            <div class="col-md-3">
              <SelectGroup :options="users"
                           v-model="selectedUserId"
                           title="title"
                           :showlabel="true"
                           value="id"
                           class="mb-0"
                           label="Выберите пользователя"
                           variant="white"
                           placeholder="Выберите пользователя"
                           v-if="users.length > 0"/>
            </div>
            <div class="col-md-3">
              <button @click="loadBonuses"
                      :disabled="!selectedUserId || loading"
                      class="btn btn-themed btn-themed-squared high-btn">
                {{ $t('generate') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="text-right">
          <a class="btn btn-themed gray d-md-inline-flex d-block align-items-center excel-btn"
             v-if="loaded"
             @click="excel">
            <ExcelSvg class="svg-gray mr-md-2"/>
            <span>{{ $t('excel') }}</span>
          </a>
        </div>
      </div>
    </div>


    <DataTableHtml :columns="columns"
                   ref="datatable"
                   v-if="loaded"
                   primaryKey="none"
                   initialOrder="name"
                   initialSort="asc"
                   :rows="bonusData"/>
  </section>
</template>

<script setup>
import { EloquentService, SuperadminService } from "@/services/api.service"
import { getCurrentInstance, onBeforeMount, onMounted, ref } from "vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import DateGroup from "@/components/form/DateGroup.vue"
import moment from "moment"
import ExcelSvg from '@/assets/svg-vue/general/excel.svg'
import DataTableHtml from "@/components/parts/general/DataTableHtml.vue"

const loaded = ref(false)
const loading = ref(false)
const users = ref([])
const bonusData = ref(null)
const selectedUserId = ref(null)
const from = ref(null)
const to = ref(null)
const datatable = ref(null)
let i18n = null

const columns = [
  {
    id: 'paid_at',
    field: 'paid_at',
    label: 'Дата оплаты',
    format_datetime: true,
    sortable: true
  },
  {
    id: 'clinic_id',
    field: 'clinic_id',
    label: 'Id клиники',
    search_select: true,
    sortable: true
  },
  {
    id: 'clinic_name',
    field: 'clinic_name',
    label: 'Клиника',
    tabletdclass: "order-mob-0",
    search_select: true,
    sortable: true
  },
  {
    id: 'is_initial_payment',
    field: 'is_initial_payment',
    label: 'Первичная оплата',
    format_boolean: true,
  },
  {
    id: 'first_payment_date',
    field: 'first_payment_date',
    label: 'Дата первого платежа',
    format_date: true,
  },
  {
    id: 'full_months_after_initial',
    field: 'full_months_after_initial',
    label: 'Месяцев с первичной оплаты',
  },
  {
    id: 'pay_sum_kzt_whole',
    field: 'pay_sum_kzt_whole',
    label: 'Оплата в тенге',
    format_number: true,
    sortable: true,
    totals: true
  },
  {
    id: 'pay_system_full',
    field: 'pay_system_full',
    label: 'Способ оплаты',
    search_select: true,
    sortable: true
  },
  {
    id: 'percent',
    field: 'percent',
    label: 'Процент',
    format_number: true,
    sortable: true,
  },
  {
    id: 'bonus',
    field: 'bonus',
    label: 'Бонус',
    format_number: true,
    sortable: true,
    totals: true
  },
]

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
})

onMounted(() => {
  to.value = moment().format('YYYY-MM-DD')
  from.value = moment().subtract(1, 'month').format('YYYY-MM-DD')
  loadUsers()
})

const loadUsers = async () => {
  const data = await EloquentService.dataTable('super_user', 1, {
    pagination: 1000,
    condition: {
      role: ['partner', 'manager', 'account_manager'],
      blocked: 0,
    }
  }, true, true)
  users.value = data.data.result.data.map((user) => ({
    id: user.id,
    title: `${i18n.t(user.role)}: ${user.full_name}`
  }))
}

const excel = () => {
  datatable.value.excel()
}

const loadBonuses = async() => {
  if(!selectedUserId.value) return
  loaded.value = false
  loading.value = true
  try {
    const res = await SuperadminService.loadBonuses({
      userId: selectedUserId.value,
      from: from.value,
      to: to.value
    })
    bonusData.value = res.data
  } finally {
    loading.value = false
    loaded.value = true
  }
}
</script>

<style scoped>
.high-btn {
  height: 46px;
}
</style>
