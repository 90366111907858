<template>
  <section id="gift-page" class="pb-5 pt-2">
    <div class="container ml-0">
      <div class="section-top-row">
        <h1>{{ $t('referral_system_for_users') }}</h1>
        <div class="row">
          <div class="col-lg-6">
            <div class="gift-card">
              <div class="row align-items-center">
                <div class="col-md-7 mb-md-0 mb-3">
                  {{ $t('recommend_dentist_plus_to_your_friends') }}
                </div>
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-lg-12 col-7">
                      <div class="gift-card-bg">
                        <div class="gift-card-subtitle text-primary font-weight-bold">{{ formatNumber(reward) }} {{ currency }}</div>
                        <div>{{ $t('immediately_after_first_payment_of_your_referral') }}</div>
                      </div>
                    </div>
                    <div class="col-5 d-lg-none d-block">
                      <img class="cubes-img"
                           src="/images/gift/cubes.png"
                           alt="cubes"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 text-lg-right d-lg-block d-none">
            <img class="cubes-img"
                 src="/images/gift/cubes.png"
                 alt="cubes"/>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <h1>{{ $t('how_referral_system_works') }}</h1>
        <div class="row">
          <div class="col-lg-6 mb-lg-0 mb-3">
            <div class="gift-card people-bg h-100">
              <div class="row">
                <div class="col-lg-8 order-lg-0 order-1">
                  <h2>{{ $t('recommend_dentist_plus_to_your_friends_with_any_method') }}</h2>
                  <ol>
                    <li><span>{{ $t('let_your_friend_scan_qr_below') }}</span></li>
                    <li><span>{{ $t('fill_form_yourself') }}</span></li>
                  </ol>
                </div>
                <div class="col-lg-4 col-12 order-0 order-lg-1 text-right mb-lg-0 mb-5">
                  <div class="gift-step">1/2</div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-lg-6">
            <div class="gift-card pyramid-bg h-100">
              <div class="row">
                <div class="col-lg-7 order-lg-0 order-1">
                  <h2 v-html="$t('receive_reward_for_first_payment', { reward: formatNumber(reward), currency })"/>
                  <div>{{ $t('receive_payment_from_our_manager_on_your_card') }}</div>
                </div>
                <div class="col-lg-5 col-12 order-0 order-lg-1 text-right mb-lg-0 mb-5">
                  <div class="gift-step">2/2</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <h1>{{ $t('start_earning_right_now') }}</h1>
        <div class="position-relative">
          <div class="row">
            <div class="col-lg-6 mb-lg-0 mb-3">
              <div class="gift-card h-100">
                <div class="row align-items-center">
                  <div class="col-md col-12 text-md-left text-center mb-md-0 mb-3">
                    <div class="let-scan">
                      {{ $t('let_your_friend_scan_qr') }}
                    </div>
                  </div>
                  <div class="col-md-auto col-12 text-md-left text-center">
                    <qrcode-vue :value="qr_code"
                                foreground="#0787C9"
                                :size="122"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="position-relative h-100">
                <div class="or-card">{{ $t('or') }}</div>
                <div class="gift-card h-100">
                  <h2>{{ $t('fill_form_with_data_of_whom_you_recommend_and_we_will_contact_you') }}</h2>
                  <GiftFormBlock :user-id="user.id"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { formatNumber } from "@/extensions/filters/filters"
import QrcodeVue from 'qrcode.vue'
import { computed, getCurrentInstance, onBeforeMount, ref } from "vue"
import { useHead } from "@unhead/vue"
import GiftFormBlock from "@/components/parts/marketing/GiftFormBlock.vue"
import { useStore } from "vuex"

const store = useStore()
let i18n = null

const title = ref('')

const rewardMapping = {
  'kzt': 40000,
  'usd': 75,
  'rub': 8000
}

const currencyMapping = {
  'kzt': 'тенге',
  'usd': 'USD',
  'rub': 'рублей'
}

const user = computed(() => store.state.auth.user)
const clinic = computed(() => store.state.auth.clinic)
const billingCurrency = computed(() => clinic.value?.billing_currency || 'USD')
const qr_code = computed(() => `${location.origin}/gift/form?uid=${user.value.id}`)
const currency = computed(() => currencyMapping[billingCurrency.value] || 'usd')
const reward = computed(() => rewardMapping[billingCurrency.value] || 'usd')

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('referral_system_for_users')
})

useHead({
  title: () => title.value,
})
</script>

<style scoped lang="scss">
#gift-page {
  font-size: 15px;
}
h1, h2 {
  font-weight: bold;
}
h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
h2 {
  font-size: 18px;
}
.gift-card {
  border-radius: 12px;
  border: 1px solid #DEE2E6;
  padding: 20px;
}
.gift-card-bg {
  border-radius: 6px;
  background: $body-bg;
  padding: 10px;
  .gift-card-subtitle {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.cubes-img {
  max-width: 240px;
}
.or-card {
  position: absolute;
  display: inline-flex;
  left: 0;
  top: 50%;
  border: 1px solid #DEE2E6;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 2;
  width: 50px;
  height: 32px;
  border-radius: 50px;
  transform: translateY(-50%);
  margin-left: -40px;
}
.people-bg {
  background: url('/public/images/gift/people.png') no-repeat bottom -20px right 20px;
  background-size: 165px;
  min-height: 210px;
}
.pyramid-bg {
  background: url('/public/images/gift/pyramid.png') no-repeat bottom -20px right 20px;
  background-size: 300px;
  min-height: 210px;
}
.gift-step {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: $blue-color;
  border: 1px solid #DEE2E6;
  border-radius: 50px;
}
ol {
  padding-left: 20px;
  li {
    span {
      margin-left: 6px;
      display: block;
    }
  }
}
@media screen and (max-width: 992px) {
  #gift-page {
    font-size: 14px;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  .cubes-img {
    width: 100%;
  }
  .people-bg {
    background: url('/public/images/gift/people.png') no-repeat top 20px left 20px;
    background-size: 68px;
    min-height: 210px;
  }
  .pyramid-bg {
    background: url('/public/images/gift/pyramid.png') no-repeat top 20px left 20px;
    background-size: 116px;
    min-height: 210px;
  }
  .gift-step {
    width: 40px;
    height: 40px;
  }
  .let-scan {
    max-width: 180px;
    margin: 0 auto;
  }
  .or-card {
    left: 50%;
    transform: translateY(0);
    top: 0;
    margin-left: -25px;
    margin-top: -24px;
    padding: 4px 8px;
  }

}
</style>
